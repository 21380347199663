import createAPI from './APIRequest';

export {clipNewsTop};

const API = createAPI();
function _clipNews(el) {
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    const $clipimg = document.getElementById('clip_notification');
    const $bottom = document.querySelector('.js-clip-news-bottom');
    const $side = document.querySelector('.js-clip-news-side');
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        $clipimg.classList.remove('is-on');
        $clipimg.classList.remove('is-off');
      },
      (response) => {
        if ($current.dataset.clipMethod === 'POST') {
          updatePost($clipimg,$current,$bottom,$side,response.total_count);
        } else {
          updateDelete($clipimg,$current,$bottom,$side,response.total_count);
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        }else if(status === 422) {
          //既にクリップしていたら
          if(response.data['news_id'] && response.data['news_id']['duplicateError']){
            updatePost($clipimg,$current,$bottom,$side,null);
          }
        }else if(status === 404 && $current.dataset.clipMethod === 'DELETE') {
          updateDelete($clipimg,$current,$bottom,$side,null);
        }
      }
    );
  });
}

function updatePost($clipimg,$current,$bottom,$side,total_count){
  // ポップアップCSSアニメーション開始
  $clipimg.classList.remove('is-off');
  $clipimg.classList.add('is-on');
  // dataset更新
  $current.dataset.clipMethod = 'DELETE';
  // カウンター更新
  if($current.querySelector('.clips-counter') && total_count !== null) {
    $current.querySelector('.clips-counter').textContent = total_count;
  }
  // カラー更新
  $current.querySelector('i.babysymbol-clip').classList.remove('c-gray');
  $current.querySelector('i.babysymbol-clip').classList.add('c-pink');

  if($bottom) {
    $bottom.dataset.clipMethod = 'DELETE';
    const $babysymbol = $bottom.querySelector('.babysymbol');

    if($bottom.querySelector('.clips-counter') && total_count !== null) {
      $bottom.querySelector('.clips-counter').textContent = total_count;
    }

    if($babysymbol){
      $babysymbol.classList.add('c-pink');
      $babysymbol.classList.remove('c-gray');
      $babysymbol.style.color = null;
    }
  }

  if($side) {
    $side.dataset.clipMethod = 'DELETE';
    {
      const $sideOn = document.getElementById('clipDetail');
      $sideOn && $sideOn.classList.add('is-on');
    }
  }
}

function updateDelete($clipimg,$current,$bottom,$side,total_count){
  // ポップアップCSSアニメーション開始
  $clipimg.classList.remove('is-on');
  $clipimg.classList.add('is-off');
  // dataset更新
  $current.dataset.clipMethod = 'POST';
  // カウンター更新
  if($current.querySelector('.clips-counter') && total_count !== null) {
    $current.querySelector('.clips-counter').textContent = total_count;
  }
  // カラー更新
  $current.querySelector('i.babysymbol-clip').classList.remove('c-pink');
  $current.querySelector('i.babysymbol-clip').classList.add('c-gray');

  if($bottom) {
    $bottom.dataset.clipMethod = 'POST';
    const $babysymbol = $bottom.querySelector('.babysymbol');

    if($bottom.querySelector('.clips-counter') && total_count !== null) {
      $bottom.querySelector('.clips-counter').textContent = total_count;
    }

    if($babysymbol){
      $babysymbol.classList.remove('c-pink');
      $babysymbol.classList.add('c-gray');
      $babysymbol.style.color = null;
    }
  }

  if($side) {
    $side.dataset.clipMethod = 'POST';
    {
      const $sideOn = document.getElementById('clipDetail');
      $sideOn && $sideOn.classList.remove('is-on');
    }
  }
}

async function clipNewsTop(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipNews(el));
  }
}
