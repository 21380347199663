import createAPI from './APIRequest';

export {modalCommentDelete};

const API = createAPI();
async function modalCommentDelete(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      let submit = document.querySelector('.is-submit');
      let cancel = document.querySelector('.is-cancel');
      onSubmit(el,submit,cancel);

      onCancel(el,cancel);

    });
  }
}

function onSubmit(el,submit,cancel) {
  submit.addEventListener('click',event => {
    let comment_id = submit.dataset.commentId;
    let url = submit.dataset.postUrl.replace('comments.json', 'comments/'+comment_id+'.json');

    if(parseInt(comment_id) === -1) return;

    API.send(
      url,
      'DELETE',
      submit.dataset.postAuth,
      () => {
      },
      (response) => {
        cancel.click();
      },
      (response) => {
        location.href = submit.dataset.postComplete + '?page=' + submit.dataset.page;
      },
      (status, response) => {
        if(status === 422){
          Array.prototype.slice.call($form.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
          Array.prototype.slice.call($form.querySelectorAll('.errors'), 0).forEach($error => $error.remove());
        }else{
          cancel.click();
        }
      }
    );
  });
}

function onCancel($form,cancel) {
  cancel.addEventListener('click',event => {

    Array.prototype.slice.call($form.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
    Array.prototype.slice.call($form.querySelectorAll('.errors'), 0).forEach($error => $error.remove());
  });
}
