
// コメントへの返信開く
document.addEventListener('DOMContentLoaded', function(){
  function menuClick(){
      cl = this.closest('li');
      cl.classList.toggle('is-reply-open');
  }

  // 引数に指定したclassの値をもつ要素をすべて取得
  const menus = document.getElementsByClassName('c-btn-res');
  // 上記で取得したすべての要素に対してクリックイベントを適用
  for(let i = 0; i < menus.length; i++) {
      menus[i].addEventListener('click', menuClick);
  }

});

// コメントもっと読む
document.addEventListener('DOMContentLoaded', function(){
  function menuClick(){
      cl = this.closest('li');
      cl.classList.toggle('is-readmore-open');
  }

  // 引数に指定したclassの値をもつ要素をすべて取得
  const menus = document.getElementsByClassName('js-btn-readmore');
  // 上記で取得したすべての要素に対してクリックイベントを適用
  for(let i = 0; i < menus.length; i++) {
      menus[i].addEventListener('click', menuClick);
  }

});



