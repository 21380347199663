import {calculateBirth} from "./calculateBirth";

export {setActiveWeek}

function _setActiveWeek(elements,options){
  elements.forEach( el => {
    let birth;
    if (options && options.childBirth) {
      birth = options.childBirth
    }else{
      birth = el.dataset.childBirth;
    }
    let age = calculateBirth(birth);
    const links = el.getElementsByClassName('is-link');
    const arrayLinks = Array.prototype.slice.call(links);
    let period = Math.floor((280 - age.days)/7)
    let start = 4;
    if (el.dataset.start) {
      start = el.dataset.start;
    }
    arrayLinks.forEach($link => {
      if (start == period) {
        $link.classList.add('is-now');
      }
      start++;
    })
  });
}

async function setActiveWeek(elements,options) {
  if (elements.length > 0) {
    if (!options) {
      options = '';
    }
    _setActiveWeek(elements,options);
  }
}
