export {previewFormFields};

function open(options) {
  let requiredError = false;

  if (options.textFields && (options.textFields.length > 0)) {
    // テキストプレビュー
    options.textFields.forEach(element => {
      const $previewBody = document.getElementById(element + 'Preview');
      while ($previewBody.firstChild) {
        $previewBody.removeChild($previewBody.firstChild);
      }
      let bodyValue = document.getElementById(element).value;
      bodyValue === '' && document.getElementById(element).required && (requiredError = true);
      bodyValue = bodyValue.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
      const values = bodyValue.split('\n');
      for (let i = 0; i < values.length; i++) {
        if (i > 0) {
          $previewBody.appendChild(document.createElement('br'));
        }
        const $span = document.createElement('span');
        $span.textContent = values[i];
        $span.innerHTML = $span.innerHTML.replace(/((?:https?|ftp):\/\/[^\s　]+)/iu, '<a href="$1" target="_blank">$1</a>');
        $previewBody.appendChild($span);
      }
    });
  }
  if (options.imgFields && (options.imgFields.length > 0)) {
    // 画像プレビュー
    options.imgFields.forEach(element => {
      const $previewPhotos = document.getElementById(element + 'Preview');
      while ($previewPhotos.firstChild) {
        $previewPhotos.removeChild($previewPhotos.firstChild);
      }
      const $photos = document.querySelectorAll('.' + element);
      $photos.forEach($photo => {
        const $img = $photo.querySelector('figure.image img');
        if ($img) {
          const $figure = document.createElement('figure');
          $figure.classList.add('image', 'is-64x64', 'is-inline-block', 'is-marginless');
          $figure.appendChild($img.cloneNode(false));
          $previewPhotos.appendChild($figure);
        }
      });
    });
  }

  if (options.selectFields && (options.selectFields.length > 0)) {
    // セレクトボックスプレビュー
    var ele = document.getElementsByName('radio');

    for(var i = 0; i < ele.length; i++) {
      if(ele[i].checked){
        const $previewRadio = document.getElementById(options.selectFields + 'Preview');
        while ($previewRadio.firstChild) {
          $previewRadio.removeChild($previewRadio.firstChild);
        }
        const $span = document.createElement('span');
        $span.textContent = ele[i].value;
        $previewRadio.appendChild($span);
      }
    }
  }

  if (options.others && (options.others.length > 0)) {
    // その他
    options.others.forEach(element => {
      const $preview = document.getElementById(element + 'Preview');
      const $block = document.getElementById(element);
      while ($block.firstChild) {
        $preview.appendChild($block.firstChild);
      }
    });
  }

  // モーダルオープン
  if (requiredError && document.getElementById(options.modal + 'RequiredError')) {
    document.getElementById(options.modal + 'RequiredError').classList.add('is-active');
  } else {
    document.getElementById(options.modal).classList.add('is-active');
  }
}

function close(options) {
  // その他
  if (options.others && (options.others.length > 0)) {
    options.others.forEach(element => {
      const $preview = document.getElementById(element + 'Preview');
      const $block = document.getElementById(element);
      while ($preview.firstChild) {
        $block.appendChild($preview.firstChild);
      }
    });
  }

  // モーダルクローズ
  document.getElementById(options.modal).classList.remove('is-active');
}

function showPreviewModal(e) {
  const options = JSON.parse(e.currentTarget.dataset.previewFormFieldsOptions);
  if (document.getElementById(options.modal).classList.contains('is-active')) {
    close(options);
  } else {
    open(options);
  }
}

async function previewFormFields(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.addEventListener('click', showPreviewModal);
    });
  }
}
