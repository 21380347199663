import {clipNewsSeries} from "../clipNewsSeries";

export {createClipNewsSeriesBlock};

function createClipNewsSeriesBlock(clipped, options, elementName) {
  const $clipBlock = document.createElement('button');
  $clipBlock.classList.add('button', 'is-rounded','is-fullsize', 'is-clip', 'js-clip-news-series');
  if (elementName === 'clipNewsSeries1') {
    $clipBlock.classList.add('is-small', 'gtm-clip-ns1');
    {
      const $icon = document.createElement('span');
      $icon.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5', (clipped ? 'c-pink' : 'c-gray'));
      $clipBlock.appendChild($icon);
    }
    {
      const $span = document.createElement('span');
      $span.classList.add('clips-counter');
      $span.textContent = options.clips_count;
      $clipBlock.appendChild($span);
    }
    {
      const $span = document.createElement('span');
      $span.textContent = 'クリップ';
      $clipBlock.appendChild($span);
    }
  } else if (elementName === 'clipNewsSeries2') {
    $clipBlock.classList.add('is-big', 'is-fullsize', (clipped ? 'is-disable' : 'is-primary'), 'gtm-clip-ns2');
    $clipBlock.style.pointerEvents = 'initial';
    $clipBlock.textContent = clipped ? 'クリップを解除' : 'この連載をクリップ';
  } else if (elementName === 'clipNewsSeries3') {
    $clipBlock.classList.add('is-big', 'is-fullsize', (clipped ? 'is-disable' : 'is-primary'), 'gtm-clip-ns3');
    $clipBlock.style.pointerEvents = 'initial';
    $clipBlock.textContent = clipped ? 'クリップを解除' : 'この連載をクリップ';
  }
  $clipBlock.dataset.clipUrl = options.url;
  $clipBlock.dataset.clipMethod = clipped ? 'DELETE' : 'POST';
  $clipBlock.dataset.clipAuth = options.auth;
  $clipBlock.dataset.clipBody = options.body;
  $clipBlock.dataset.clipLogin = options.login;
  $clipBlock.dataset.clipNotification = options.notification;
  clipNewsSeries([$clipBlock]);
  return $clipBlock;
}
