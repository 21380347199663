import Swiper from 'swiper/bundle';

export {swipeGalleryWithThumbs};

async function swipeGalleryWithThumbs(galleries) {
  const swipers = [];
  if (galleries.length > 0) {
    galleries.forEach(el => {
      if (el.dataset.swiperHistory) {
        let url = location.pathname;
        if (url === '/' + el.dataset.swiperHistory) {
          url = location.pathname + '/';
        }
        if (url === '/' + el.dataset.swiperHistory + '/' || url === el.dataset.swiperHistory) {
          const value = el.querySelector('.swiper-slide').dataset.history;
          history.replaceState({value: value}, null,  url + value);
        }
      }
      const $thumbs = document.getElementById(el.dataset.swiperThumbsId);
      const thumbsOptions = JSON.parse($thumbs.dataset.swiperOptions);
      const thumbsSwiper = new Swiper($thumbs, thumbsOptions);

      const galleryOptions = {
        spaceBetween: 30,
        autoHeight: true,
        history: el.dataset.swiperHistory ? {
          replaceState: true,
          key: el.dataset.swiperHistory
        } : false,
        speed: parseInt(el.dataset.swiperSpeed),
        thumbs: {
          swiper: thumbsSwiper
        }
      };

      const gallerySwiper = new Swiper(el, galleryOptions);
      if (document.body.dataset.state === "null") {
        const defaultSlide = parseInt(el.dataset.initialSlide);
        gallerySwiper.slideTo(defaultSlide,0,false);
      }
      // サイドカラムの検索表示非表示：ロード時
      // 妊娠前-婦人科
      const elem1 = document.getElementById('slide_prepregnancy');
      const search1 = document.getElementById('search_gynecology');
      // 妊娠中-産婦人科
      const elem2 = document.getElementById('slide_pregnancy');
      const search2 = document.getElementById('search_hospital');
      // 育児-産後ケア
      const elem3 = document.getElementById('slide_baby');
      const search3 = document.getElementById('search_postpartum_care');
      // 出産、不妊
      const elem4 = document.getElementById('slide_birth');
      const elem5 = document.getElementById('slide_infertility');

      const top_category_list = ['投票箱','履歴','ランキング','ニュース','マンガ','連載マンガ','テーマ','エンタメ','特集','食・レシピ','ママトピ','暮らし','グッズ','医療','イベント','制度・支援'];

      if(
        elem1 && elem1.classList.contains('swiper-slide-thumb-active') === true ){
        search1.classList.remove('is-hidden');
      }else if(
        elem2 && elem2.classList.contains('swiper-slide-thumb-active') === true ){
        search2.classList.remove('is-hidden');
      }else if(
        elem3 && elem3.classList.contains('swiper-slide-thumb-active') === true ){
        search3.classList.remove('is-hidden');
      }

      //ニュースtop タイトル変更
      const top_title = document.getElementsByClassName('top-title');
      const index = parseInt(el.dataset.initialSlide);
      if(top_category_list[index] && top_title.length > 0){
        top_title[0].textContent = top_category_list[index];
      }

      gallerySwiper.on('slideChange', function () {
          if( elem1 && elem1.classList.contains('swiper-slide-thumb-active') === true ){
            search1.classList.remove('is-hidden');
            search2.classList.add('is-hidden');
            search3.classList.add('is-hidden');
          }
          if( elem2 && elem2.classList.contains('swiper-slide-thumb-active') === true ){
            search1.classList.add('is-hidden');
            search2.classList.remove('is-hidden');
            search3.classList.add('is-hidden');
          }
          if( elem3 && elem3.classList.contains('swiper-slide-thumb-active') === true ){
            search1.classList.add('is-hidden');
            search2.classList.add('is-hidden');
            search3.classList.remove('is-hidden');
          }
          if( elem4 && elem4.classList.contains('swiper-slide-thumb-active') === true ){
            search1.classList.add('is-hidden');
            search2.classList.add('is-hidden');
            search3.classList.add('is-hidden');
          }
          if( elem5 && elem5.classList.contains('swiper-slide-thumb-active') === true ){
            search1.classList.add('is-hidden');
            search2.classList.add('is-hidden');
            search3.classList.add('is-hidden');
          }

          const index = gallerySwiper.activeIndex;
          if(top_category_list[index] && top_title.length > 0){
            top_title[0].textContent = top_category_list[index];
          }
        });

      const slides = Array.prototype.slice.call(el.querySelectorAll('.swiper-slide'), 0);
      if (slides.length > 0) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            gallerySwiper.updateAutoHeight();
          });
        });
        for (let i = 0; i < slides.length; i++) {
          observer.observe(slides[i], {childList: true, subtree: true});
        }
      }
      swipers.push(gallerySwiper);
    });
  }
  return swipers;
}
