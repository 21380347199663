export {floatingToApp};

// var adOffset, adSize, winH;
//
// $(window).on('load resize', function () {
//   adOffset = $('.l-footer').offset().top;
//   winH = $(window).height();
// });
// $(function () {
//   var message = $('.js-floating');
//   message.hide();
//   //スクロール量がmesPointに達していなければmessageを非表示、達して入れば表示します
//   $(window).scroll(function () {
//     if ($('.reaction_block').length > 0) {
//       adOffset = $('.reaction_block').offset().top;
//     }
//     if ($(this).scrollTop() > adOffset - winH) {
//       message.fadeOut();
//     } else if ($(this).scrollTop() > 70) {
//       message.fadeIn();
//     } else {
//       message.fadeOut();
//     }
//   });
// });
//
// $('.floating_btn-close-icon').on('click', function () {
//   // アプリで読むを非表示にする
//   $('.js-floating').remove();
//
//   // Cookieを追加する
//   $.cookie('news_app_ad', '1');
// });

async function floatingToApp(elements) {
  if (elements.length > 0) {
    elements.forEach( el => {
      el.addEventListener('click', (event) => {
        _floatingToApp();
        event.preventDefault();
      });
    });
  }
}

function _floatingToApp(){
  Array.prototype.slice.call(document.querySelectorAll('.js-floating'), 0).forEach($floating => {
    $floating.remove();
  });
  document.cookie = 'news_app_ad=1';
}
