import moment from 'moment/moment'

export {calculateBirth}

function calculateBirth(birthDate) {
  return getAge(birthDate);
}
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  return dateDiff(birthDate, today);
}
function dateDiff(startdate, enddate) {
  var startdateMoment = moment(startdate);
  var enddateMoment = moment(enddate);
  var years = enddateMoment.diff(startdateMoment, 'years');
  var months = enddateMoment.diff(startdateMoment, 'months') - (years * 12);
  var weeks = startdateMoment.diff(enddateMoment, 'weeks');
  var days = startdateMoment.diff(enddateMoment, 'days')
  return {
    years: years,
    months: months + (years * 12),
    days: days + 1,
    weeks: weeks
  };
}
