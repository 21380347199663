import createAPI from './APIRequest';

export {modalHelpfulReport};

const API = createAPI();

// 役にたった情報の送信
async function modalHelpfulReport(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      let is_helpful_submit = document.querySelector('.is-helpful-submit'); // はい
      let not_helpful_submit = document.querySelector('.not-helpful-submit'); // いいえ

      // 登録する
      _addReport(el,is_helpful_submit,not_helpful_submit);

    });
  }

}

// 情報を登録する
function _addReport(el,is_helpful_submit,not_helpful_submit){

  // はいボタンを押した場合(はいでAPI送信する)
  is_helpful_submit.addEventListener('click', (event) => {
    _sendApi(is_helpful_submit, 1);
  });

  // いいえボタンを押した場合(いいえでAPI送信する)
  not_helpful_submit.addEventListener('click', (event) => {
    _sendApi(is_helpful_submit,0);
  });

}

  // API送信する
  function _sendApi(submit,reaction){

    let data_resource_url = submit.dataset.resourceUrl;
    let data_reaction_url = submit.dataset.reactionUrl;
    let data_access_token = submit.dataset.accessToken;

    // API送信
    API.send(
      data_resource_url, // APIのURL
      'POST', // method
      '', // auth
      {
        url: data_reaction_url,
        cookie: data_access_token,
        reaction: reaction
      }, // body
      () => {
        // before_send
      },
      (response) => {
        // callback
      },
      (status, response) => {
        // error
      }
    );

}
