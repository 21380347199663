
export {selectIndexNewsSeriesTag};

async function selectIndexNewsSeriesTag(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      onSelect(el);
    });
  }
}

function onSelect(el) {
  el.addEventListener('change',event => {
    let idx = event.target.selectedIndex;
    let value = event.target.options[idx].value;
    let url = location.pathname;
    let distance = el.dataset.resourcesDistance;

    if(value){
      url = url + '?distance=' + distance + '&tag_id=' + value;
    }
    location.href = url;

  });
}
