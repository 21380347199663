

// tab
import {searchResources} from "./searchResources";

const tabs = document.getElementsByClassName('tab');
for(let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', tabSwitch);
}

// タブをクリックすると実行する関数
function tabSwitch(){
    // 引数で指定したセレクターと一致する直近の祖先要素を取得
    const ancestorEle = this.closest('.js-tab-panel');
    // タブのclassの値を変更
    ancestorEle.getElementsByClassName('is-active')[0].classList.remove('is-active');
    this.classList.add('is-active');
    // コンテンツのclassの値を変更
    ancestorEle.getElementsByClassName('is-show')[0].classList.remove('is-show');
    const groupTabs = ancestorEle.getElementsByClassName('tab');
    const arrayTabs = Array.prototype.slice.call(groupTabs);
    const index = arrayTabs.indexOf(this);
    ancestorEle.getElementsByClassName('panel')[index].classList.add('is-show');
  let url = location.pathname;
  if((url.indexOf('/keywords') !== -1))
  {
    let value = this.dataset.history;
    if ((url.indexOf('/'+value) === -1)) {
      history.replaceState({value: value}, null,  '/knowledge/keywords/' + value);
      changeUrl();
    }
    const search = document.querySelectorAll('.search');
    let i = 0;
    search.forEach(el => {
      if (i == index) {
        el.classList.add('js-search-resources-keywords' + index);
        const ele = document.querySelectorAll('.js-search-resources-keywords' + index);
        searchResources(ele);
      }
      i++;
    });
  }
};
