import createAPI from './APIRequest';

export {postNewsComment};

const API = createAPI();
async function postNewsComment(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      let textarea = el.querySelector('.comment_contents');
      let submit = el.querySelector('.p-comment-form-btn');
      let comment_num = el.querySelector('.comment_num');

      if(!textarea) return;

      let maxLength = textarea.dataset.max;

      //文字数カウント
      if(maxLength){
        setCommentNum(textarea,comment_num,maxLength);
      }

      //submitボタンの活性化
      onKeyup(textarea,submit,comment_num,maxLength);

      //コメント投稿
      onSubmit(el,textarea,submit,comment_num,maxLength);

    });
  }
}
function renderError($form,allErrors) {

  Array.prototype.slice.call($form.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
  Array.prototype.slice.call($form.querySelectorAll('.errors'), 0).forEach($error => $error.remove());

  for (let model in allErrors) {
    let errors = allErrors[model];
    for (let field in errors) {

      var $target = null;
      if(field === 'contents'){
        $target = $form.querySelector('.comment_contents');
      }else{
        $target = document.getElementById(field);
      }
      if (!$target) {
        continue;
      }
      $target.classList.add('is-danger');
      let fieldErrors = errors[field];
      let $fieldErrors = document.createElement('div');
      $fieldErrors.classList.add('errors', 'help', 'is-danger');
      if (Array.isArray(fieldErrors)) {
        for (let i = 0; i < fieldErrors.length; i++) {
          for (let subField in fieldErrors[i]) {
            let subFieldErrors = fieldErrors[i][subField];
            for (let key in subFieldErrors) {
              let $fieldError = document.createElement('p');
              $fieldError.textContent = subFieldErrors[key];
              $fieldErrors.appendChild($fieldError);
            }
          }
        }
      } else {
        for (let key in fieldErrors) {
          let $fieldError = document.createElement('p');
          $fieldError.textContent = fieldErrors[key];
          $fieldErrors.appendChild($fieldError);
        }
      }
      $target.parentNode.insertBefore($fieldErrors, $target.nextSibling);
    }
  }
}

function onSubmit(el,textarea,submit,comment_num,maxLength) {

  el.addEventListener('submit',event => {
    event.preventDefault();

    let comment = textarea.value;
    let body = el.dataset.commentBody + '&contents=' + comment;

    API.send(
      el.dataset.commentUrl,
      el.dataset.commentMethod,
      el.dataset.commentAuth,
      body,
      () => {

      },
      (response) => {

        Array.prototype.slice.call(el.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
        Array.prototype.slice.call(el.querySelectorAll('.errors'), 0).forEach($error => $error.remove());

        textarea.value = '';
        comment_num.text = '2,000';

        comment = htmlEscape(comment);

        comment = comment.replace(/\r\n/g, "<br />");
        comment = comment.replace(/(\n|\r)/g, "<br />");

        let html = '<div class="p-comment-area">';
        html += '<p class="u-fwb" style="font-size:14px">コメントを投稿しました</p>';
        html += '<div class="p-comment-posted u-mt-5" style="overflow-wrap: break-word">';
        html += '	<p>' + comment + '</p>';
        html += '	</div>';
        html += '</div>';

        el.style.display = "none";
        el.parentNode.innerHTML = html;

        dataLayer.push({
          'event': 'gtm-event-comment',
          'custom_eventdata_form_id': event.target.id,
          'custom_eventdata_comments_count': response.data.news_comment_count
        });
      },
      (status, response) => {
        if(status === 422){
          renderError(el,response.data);
        }else{}
      }
    );
  });

}

function setCommentNum(textarea,comment_num,maxLength) {
  let textLength = textarea.value.length;
  let len = maxLength - textLength;
  len = (len + "").replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

  comment_num.innerHTML = len;
}


function onKeyup(textarea,submit,comment_num,maxLength) {
  textarea.addEventListener('keyup',event => {
    let textLength = textarea.value.length;
    let len = maxLength - textLength;
    len = (len + "").replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

    comment_num.innerHTML = len;

    if(textLength > 0){
      submit.disabled=false;
      submit.classList.remove('disable');
    }else{
      submit.disabled=true;
      submit.classList.add('disable');
    }
  });
}

function htmlEscape(str) {
  if (!str) return;
  return str.replace(/[<>&"'`]/g, function(match) {
    const escape = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      "'": '&#39;',
      '`': '&#x60;'
    };
    return escape[match];
  });
}
