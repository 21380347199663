import createAPI from './APIRequest';

export {clipRecipe};

const API = createAPI();
function _clipRecipe(el) {
  const $babysymbol = el.querySelector('.babysymbol');
  $babysymbol.addEventListener('animationend', (event) => {
    // CSSアニメーションが終了したら必要ないのでクラスを削除する
    //el.classList.remove('is-on');
    $babysymbol.classList.add('c-pink');
    $babysymbol.classList.remove('c-gray');
    $babysymbol.style.color = null;
  });
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    $current.classList.toggle('is-on');
    let clipimg = document.getElementById('clip_notification');
    if (!clipimg) {
      clipimg = document.getElementById('clip_notification_recipe');
    }
    //let isNotification = $current.dataset.clipNotification;

    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        clipimg.classList.remove('is-on');
        clipimg.classList.remove('is-off');
        //$current.classList.add('is-loading');
        $babysymbol.classList.remove('babysymbol-clip');
      },
      (response) => {
        //$current.classList.remove('is-loading');
        $babysymbol.classList.add('babysymbol-clip');
        if ($current.dataset.clipMethod === 'POST') {
          // ポップアップCSSアニメーション開始
          /*if(isNotification === '1'){
            clipimg.classList.add('is-on');
            clipimg.classList.remove('is-off');
          }*/
          clipimg.classList.add('is-on');
          clipimg.classList.remove('is-off');
          // dataset更新
          $current.dataset.clipMethod = 'DELETE';
          // カウンター更新
          if($current.querySelector('.clips-counter')) {
            $current.querySelector('.clips-counter').textContent = response.total_count;
          }
          // アイコンCSSアニメーション開始
          $current.classList.add('is-on');
        } else {
          // ポップアップCSSアニメーション開始
          /*if(isNotification === '1'){
            clipimg.classList.remove('is-on');
            clipimg.classList.add('is-off');
          }*/
          clipimg.classList.remove('is-on');
          clipimg.classList.add('is-off');
          // dataset更新
          $current.dataset.clipMethod = 'POST';
          // html 更新
          $current.classList.remove('is-on');

          // カウンター更新
          if($current.querySelector('.clips-counter')) {
            $current.querySelector('.clips-counter').textContent = response.total_count;
          }

          $babysymbol.classList.add('c-gray');
          $babysymbol.classList.remove('c-pink');
          $babysymbol.style.color = null;
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        }else if(status === 422) {
          //$current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
          //既にクリップしていたら
          if(response.data['recipe_id'] && response.data['recipe_id']['duplicateError']){
            // dataset更新
            $current.dataset.clipMethod = 'DELETE';
            // アイコンCSSアニメーション開始
            $current.classList.add('is-on');
          }
        }else if(status === 404 && $current.dataset.clipMethod === 'DELETE') {
          //$current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');

          // dataset更新
          $current.dataset.clipMethod = 'POST';
          // html 更新
          $current.classList.remove('is-on');

          $babysymbol.classList.add('c-gray');
          $babysymbol.classList.remove('c-pink');
          $babysymbol.style.color = null;
        }else{
          //$current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
        }
      }
    );
  });
}

async function clipRecipe(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipRecipe(el));
  }
}
