
export {createPaginatorIndexBlock};

function createPaginatorIndexBlock(el,total,current) {
  const $div = document.createElement('div');
  $div.classList.add('text-center');
  {
    const $span = document.createElement('span');
    $span.textContent = current + '/' + total;
    $div.appendChild($span);
  }
  return $div;
}
