import createAPI from './APIRequest';

export {madeRecipe};

const API = createAPI();
function _madeRecipe(el) {

  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    const $counter = $current.parentElement.previousElementSibling;
    //const $counter = $current.closest('.cooked-counter');
    //$current.classList.toggle('is-on');
    //let madeimg = document.getElementById('made_notification');
    //let isNotification = $current.dataset.madeNotification;

    API.send(
      $current.dataset.madeUrl,
      $current.dataset.madeMethod,
      $current.dataset.madeAuth,
      $current.dataset.madeBody,
      () => {
        $current.classList.add('is-loading');
        $current.classList.remove('is-on');
        $current.classList.remove('is-off');
      },
      (response) => {
        $current.classList.remove('is-loading');
        //$babysymbol.classList.add('babysymbol-made');
        if ($current.dataset.madeMethod === 'POST') {
          $current.classList.add('is-on');
          $current.classList.remove('is-off');
          // dataset更新
          $current.dataset.madeMethod = 'DELETE';
          // カウンター更新
          /*if($current.querySelector('.cooked-counter')) {
            $current.querySelector('.cooked-counter').textContent = response.total_count;
          }*/
          if($counter) {
            $counter.textContent = response.total_count;
          }
          // アイコンCSSアニメーション開始
          $current.classList.add('is-on');
        } else {
          $current.classList.remove('is-on');
          $current.classList.add('is-off');
          // dataset更新
          $current.dataset.madeMethod = 'POST';
          // html 更新
          //$current.classList.remove('is-on');

          // カウンター更新
          /*if($current.querySelector('.cooked-counter')) {
            $current.querySelector('.cooked-counter').textContent = response.total_count;
          }*/
          if($counter) {
            $counter.textContent = response.total_count;
          }
        }
      },
      (status, response) => {
        if(status === 422) {
          $current.classList.remove('is-loading');
          //$babysymbol.classList.add('babysymbol-made');
          //既にクリップしていたら
          if(response.data['recipe_id'] && response.data['recipe_id']['duplicateError']){
            // dataset更新
            $current.dataset.madeMethod = 'DELETE';
            // アイコンCSSアニメーション開始
            //$current.classList.add('is-on');
          }
        }else if(status === 404 && $current.dataset.madeMethod === 'DELETE') {
          $current.classList.remove('is-loading');
          //$babysymbol.classList.add('babysymbol-made');

          // dataset更新
          $current.dataset.madeMethod = 'POST';
          // html 更新
          $current.classList.remove('is-on');

        }else{
          $current.classList.remove('is-loading');
          //$babysymbol.classList.add('babysymbol-made');
        }
      }
    );
  });
}

async function madeRecipe(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _madeRecipe(el));
  }
}
