export {setReportTarget};

async function setReportTarget(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      el.addEventListener('click',event => {

        Array.prototype.slice.call(document.querySelectorAll('.js-modal-comment-report'), 0).forEach(modal => {
          let kind = document.getElementById('report_kind');
          let comment = document.getElementById('report_comment');

          kind.value = '';
          comment.value = '';

          Array.prototype.slice.call(modal.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
          Array.prototype.slice.call(modal.querySelectorAll('.errors'), 0).forEach($error => $error.remove());
        });

        let target_id = el.dataset.targetId;
        let submit = document.querySelector('.is-submit');
        submit.dataset.commentId = target_id;
      });

    });
  }
}
