export {shareSns};

function _shareSns(event){
  const $current = event.currentTarget;
  var _url;
  var _share_url = location.protocol + '//' + location.hostname + location.pathname;
  if(this.dataset.url){
    _share_url = location.protocol + '//' + location.hostname + this.dataset.url;
  }
  _share_url = encodeURIComponent(_share_url);
  if($current.classList.contains('is-facebook')){
    _url = 'https://www.facebook.com/sharer/sharer.php?u=' + _share_url;
  }else if($current.classList.contains('is-twitter') || $current.classList.contains('is-snsx')){
    _url = 'https://x.com/intent/tweet?url=' + _share_url;
  }else if($current.classList.contains('is-line')){
    _url = '//line.me/R/msg/text/?' + _share_url;
    location.href = _url;
    return;
  }
  window.open(_url, '_blank');
}

async function shareSns(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.addEventListener('click', _shareSns);
    });
  }
}
