export {hideNewsThumb};

async function hideNewsThumb(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      let target = el.dataset.thumb;
      let p = el.firstElementChild;

      if(p){
        let img = p.querySelector('img')
        if(img && target && img.getAttribute('src').indexOf(target) !== -1){
          img.style.display = 'none';
        }
      }
    });
  }
}
