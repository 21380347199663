
export {createNewsCarouselTopBlock};

function createNewsCarouselTopBlock(data, options) {
  if (data.news.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('swiper-wrapper');
    {
      data.news.forEach(($row, index) => {
        const $slide = document.createElement('div');
        $slide.classList.add('swiper-slide');
        {
          const $article_div = document.createElement('div');
          $article_div.classList.add('news-article-1clm','news-top-fixed-carousel-wrap');
          {
            const $article = document.createElement('article');
            $article.classList.add('news-article');
            {
              const $a = document.createElement('a');
              $a.id = 'clickID-newstop_carousel_' + (index + 1);
              $a.classList.add('row', 'middle-xs', 'gtm-click');
              $a.href = document.location.origin + '/smilenews/detail/' + $row.id + '/?utm_source=newstop&utm_medium=carousel&utm_campaign=carousel_231019';
              {
                const $img_div = document.createElement('div');
                $img_div.classList.add('col-xs-12','is-article-thumb');
                {
                  const $figure = document.createElement('figure');
                  $figure.classList.add('is-article-img-trim');
                  {
                    const $img = document.createElement('img');
                    $img.classList.add('is-article-img');
                    $img.src = $row.thumb_image;
                    $img.alt = $row.title;
                    $img.loading = 'lazy';
                    $img.style.width = '100%';
                    $img.style.height = 'auto';
                    $figure.appendChild($img);

                    const $span = document.createElement('span');
                    $span.classList.add('label-news', 'is-big','u-bg_category-pr');
                    $span.textContent = $row.recommend_tag_name;
                    $figure.appendChild($span);
                  }
                  $img_div.appendChild($figure);
                }
                $a.appendChild($img_div);

                const $title_div = document.createElement('div');
                $title_div.classList.add('col-xs-12','is-article-title');
                {
                  const $title = document.createElement('div');
                  $title.classList.add('title','is-2');
                  $title.textContent = $row.title;
                  $title_div.appendChild($title);
                }
                $a.appendChild($title_div);
              }
              $article.appendChild($a);
            }
            $article_div.appendChild($article);
          }
          $slide.appendChild($article_div);
        }
        $div.appendChild($slide);
      });
    }
    return $div;
  }
}
