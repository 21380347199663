const PHOTO_MAX_BYTE = 1000 * 1000 * 10; // 10MB

function MultiPhotosUploader(element) {
  // エラー情報
  this.PHOTO_MAX_BYTE = Number(element.dataset.maxSize || PHOTO_MAX_BYTE);
  this.$error = element.querySelector('.error');
  // 画像のプレビュー
  this.$preview = element.querySelector('ul');
  this.previewListClass = JSON.parse(element.dataset.uploadPhotosLiClass);
  this.previewImgClass = JSON.parse(element.dataset.uploadPhotosImgClass);
  // ファイルフォーム
  this.$input = element.querySelector('input[type=file]');
  this.$input.addEventListener('change', (e) => {
    // エラーの初期化
    this.$error.classList.add('is-hidden');
    while (this.$error.firstChild) {
      this.$error.removeChild(this.$error.firstChild);
    }
    // プレビューの初期化
    while (this.$preview.firstChild) {
      this.$preview.removeChild(this.$preview.firstChild);
    }
    // アップされたファイルを確認
    const files = e.currentTarget.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.checkFile(files[i]);
        this.previewFile(files[i]);
      }
    }
    // エラーがあれば表示
    if (this.$error.hasChildNodes()) {
      this.$error.classList.remove('is-hidden');
    }
  });
  // クリアボタン
  this.$clear = element.querySelector('.clear-files');
  this.$clear && (this.$clear.addEventListener('click', (e) => {
    this.clearFiles();
  }));
};
MultiPhotosUploader.prototype.checkFile = function (file) {
  let isValid = true;
  const _end = file.name.match(/\.(jpe?g|png|gif|bmp)$/i);
  if (!_end) {
    isValid = false;
    const $p = document.createElement('p');
    $p.textContent = '画像ファイルを指定してください。';
    this.$error.appendChild($p);
  }
  if (file.size > this.PHOTO_MAX_BYTE) {
    isValid = false;
    const $p = document.createElement('p');
    $p.textContent = (this.PHOTO_MAX_BYTE / 1000000) + 'MB以下のファイルサイズの画像を指定してください。';
    this.$error.appendChild($p);
  }
  // クリアボタンを有効にする
  isValid && this.$clear && (this.$clear.disabled = false);
};
MultiPhotosUploader.prototype.clearFiles = function () {
  this.$input.value = '';
  // エラーの初期化
  this.$error.classList.add('is-hidden');
  while (this.$error.firstChild) {
    this.$error.removeChild(this.$error.firstChild);
  }
  // プレビューの初期化
  while (this.$preview.firstChild) {
    this.$preview.removeChild(this.$preview.firstChild);
  }
  // クリアボタンを無効にする
  this.$clear && (this.$clear.disabled = true);
};
MultiPhotosUploader.prototype.openPreviewFileModal = function ($img) {
  this.previewImgClass.length > 0 && $img.classList.remove(...this.previewImgClass);
  $img.classList.add('modal-content');
  {
    const $background = document.createElement('div');
    $background.classList.add('modal-background');
    $background.addEventListener('click', (e) => this.closePreviewFileModal($img));
    $img.parentNode.insertBefore($background, $img);
  }
  {
    const $close = document.createElement('div');
    $close.classList.add('modal-close');
    $close.addEventListener('click', (e) => this.closePreviewFileModal($img));
    $img.parentNode.insertBefore($close, $img);
  }
  $img.parentNode.classList.add('modal', 'is-active');
};
MultiPhotosUploader.prototype.closePreviewFileModal = function ($img) {
  this.previewImgClass.length > 0 && $img.classList.add(...this.previewImgClass);
  $img.classList.remove('modal-content');
  {
    const $background = $img.parentNode.querySelector('.modal-background');
    $img.parentNode.removeChild($background);
  }
  {
    const $close = $img.parentNode.querySelector('.modal-close');
    $img.parentNode.removeChild($close);
  }
  $img.parentNode.classList.remove('modal', 'is-active');
};
MultiPhotosUploader.prototype.previewFile = function (file) {
  const $li = document.createElement('li');
  this.previewListClass.length > 0 && $li.classList.add(...this.previewListClass);
  const $img = document.createElement('img');
  this.previewImgClass.length > 0 && $img.classList.add(...this.previewImgClass);
  $img.addEventListener('click', (e) => {
    // 拡大モーダル
    const $img = e.currentTarget;
    if ($img.classList.contains('modal-content')) {
      this.closePreviewFileModal($img)
    } else {
      this.openPreviewFileModal($img);
    }
  });
  $img.addEventListener('load', (e) => {
    URL.revokeObjectURL(e.currentTarget.src);
  });
  $img.src = URL.createObjectURL(file);
  $li.appendChild($img);
  this.$preview.appendChild($li);
};

export default async function create(elements) {
  if (elements.length > 0) {
    elements.forEach(element => new MultiPhotosUploader(element));
  }
}
