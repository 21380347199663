import {clipNewsSide} from "../clipNewsSide";

export {createClipNewsSideBlock};

function createClipNewsSideBlock(clipped, options) {
  const $clipBlock = document.createElement('div');
  $clipBlock.classList.add('js-clip-news-side', 'gtm-clip-n3');
  $clipBlock.dataset.clipUrl = options.url;
  $clipBlock.dataset.clipMethod = clipped ? 'DELETE' : 'POST';
  $clipBlock.dataset.clipAuth = options.auth;
  $clipBlock.dataset.clipBody = options.body;
  $clipBlock.dataset.clipLogin = options.login;
  {
    const $span = document.createElement('span');
    $clipBlock.appendChild($span);
  }
  {
    const $sideOn = document.getElementById('clipDetail');
    clipped && $sideOn && $sideOn.classList.add('is-on');
  }
  clipNewsSide([$clipBlock]);
  return $clipBlock;
}
