import {clipNewsTop} from "../clipNewsTop";

export {createClipNewsTopBlock};

function createClipNewsTopBlock(clipped, options) {
  const $clipBlock = document.createElement('span');
  $clipBlock.classList.add('is-clip', 'js-clip-news-top', 'gtm-clip-n1');
  $clipBlock.dataset.clipUrl = options.url;
  $clipBlock.dataset.clipMethod = clipped ? 'DELETE' : 'POST';
  $clipBlock.dataset.clipAuth = options.auth;
  $clipBlock.dataset.clipBody = options.body;
  $clipBlock.dataset.clipLogin = options.login;
  {
    const $icon = document.createElement('i');
    $icon.classList.add('babysymbol', 'babysymbol-clip', 'mr5', (clipped ? 'c-pink' : 'c-gray'));
    $clipBlock.appendChild($icon);
  }
  {
    const $span = document.createElement('span');
    $span.classList.add('clips-counter');
    $span.textContent = options.clips_count;
    $clipBlock.appendChild($span);
  }
  clipNewsTop([$clipBlock]);
  return $clipBlock;
}
