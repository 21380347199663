
export {createBabyfoodRecipesList};

function createBabyfoodRecipesList(data, total_count, options) {
  if (data.recipes.length > 0) {
    console.log('enter');
    //const $div = document.createElement('div');
    //{
      const $div1 = document.createElement('div');
      $div1.classList.add('row','recipe-list');
      {
        data.recipes.forEach(($row, index) => {
          const $category_name = $row.category_names[1];
          let $category_class = 'm5-6';
          let $category_display = '5～6カ月ごろ';
          if ($category_name === '離乳食中期（7～8カ月ごろ）') {
            $category_class = 'm7-8';
            $category_display = '7～8カ月ごろ';
          } else if ($category_name === '離乳食後期（9～11カ月ごろ）') {
            $category_class = 'm9-11';
            $category_display = '9～11カ月ごろ';
          }else if ($category_name === '離乳食完了期（1歳～1歳6カ月ごろ）') {
            $category_class = 'y1-y1m6';
            $category_display = '1歳～1歳6カ月ごろ';
          }
          const $list = document.createElement('div');
          $list.classList.add('col-xs-12','recipe-wrap', $category_class);
          {
            const $a = document.createElement('a');
            $a.classList.add('row', 'middle-xs');
            $a.href = document.location.origin + '/baby-food-recipe/recipe/' + $row.id;
            {
              const $div2 = document.createElement('div');
              $div2.classList.add('col-xs-4','col-sm-3');
              {
                const $figure = document.createElement('figure');
                $figure.classList.add('is-recipe-img-trim');
                {
                  const $img = document.createElement('img');
                  $img.src = $row.photo;
                  $img.alt = $row.title;
                  $img.loading = 'lazy';
                  $img.style.width = '100%';
                  $img.style.height = 'auto';
                  $figure.appendChild($img);
                }
                $div2.appendChild($figure);
              }
              $a.appendChild($div2);

              const $info_div = document.createElement('div');
              $info_div.classList.add('col-xs-8','col-sm-9');
              {
                const $info_div1 = document.createElement('div');
                $info_div1.classList.add('row','is-recipe-info');
                {
                  const $stage = document.createElement('div');
                  $stage.classList.add('col-xs','col-sm-6','col-md-4');
                  {
                    const $span = document.createElement('span');
                    $span.classList.add('month-label', 'bg-op', 'color');
                    $span.textContent = $category_display;
                    $stage.appendChild($span);
                  }
                  $info_div1.appendChild($stage);

                  const $count = document.createElement('div');
                  $count.classList.add('col-xs','end-xs','recipe-nums');
                  {
                    const $made = document.createElement('div');
                    $made.classList.add('is-item');
                    {
                      const $made_icon = document.createElement('i');
                      $made_icon.classList.add('babysymbol', 'babysymbol-n_recipe');
                      $made.appendChild($made_icon);

                      const $made_span = document.createElement('span');
                      $made_span.classList.add('cooked-counter');
                      $made_span.textContent = $row.report_count;
                      $made.appendChild($made_span);

                    }
                    $count.appendChild($made);

                    const $clip = document.createElement('div');
                    $clip.classList.add('is-item');
                    {
                      const $clip_icon = document.createElement('i');
                      $clip_icon.classList.add('babysymbol', 'babysymbol-clip');
                      $clip.appendChild($clip_icon);

                      const $clip_span = document.createElement('span');
                      $clip_span.classList.add('cooked-counter');
                      $clip_span.textContent = $row.clipping_count;
                      $clip.appendChild($clip_span);
                    }
                    $count.appendChild($clip);
                  }
                  $info_div1.appendChild($count);
                }
                $info_div.appendChild($info_div1);

                const $info_div2 = document.createElement('div');
                $info_div2.classList.add('is-recipe-title', 'line-clamp', 'is-2');
                $info_div2.textContent = $row.title;
                $info_div.appendChild($info_div2);

                if ($row.hasOwnProperty('ingredient_names')) {
                  const $info_div3 = document.createElement('div');
                  $info_div3.classList.add('recipe-ingredients');
                  {
                    const $ingredients = document.createElement('p');
                    $ingredients.classList.add('line-clamp', 'is-1');
                    {
                      $row.ingredient_names.forEach($ingredient => {
                        const $ingredient1 = document.createElement('span');
                        $ingredient1.textContent = $ingredient;
                        $ingredients.appendChild($ingredient1);
                      });
                    }
                    $info_div3.appendChild($ingredients);
                  }
                  $info_div.appendChild($info_div3);
                }
              }
              $a.appendChild($info_div);

              if(options.isLogin){
                const $button = document.createElement('button');
                $button.classList.add('recipe-clip', 'is-clip', 'js-clip-recipe', 'gtm-clip-recipe');
                $button.dataset.clipUrl = options.clipUrl;
                if ($row.is_clipped) {
                  $button.dataset.clipMethod = 'DELETE';
                  $button.classList.add('is-on');
                } else {
                  $button.dataset.clipMethod = 'POST';
                }
                $button.dataset.clipBody = 'recipe_id=' + $row.id;
                $button.dataset.clipLogin = options.urlLogin;
                $button.dataset.clipNotification = '1';
                {
                  const $span1 = document.createElement('span');
                  $span1.classList.add('babysymbol', 'babysymbol-clip');
                  $button.appendChild($span1);
                }
                $a.appendChild($button);
              }else{
                const $button = document.createElement('button');
                $button.classList.add('recipe-clip', 'is-clip');
                {
                  const $a = document.createElement('a');
                  $a.href = options.urlLogin;
                  {
                    const $span2 = document.createElement('span');
                    $span2.classList.add('babysymbol', 'babysymbol-clip');
                    if($row.is_clipped){
                      $button.classList.add('is-on');
                    }
                    $a.appendChild($span2);
                  }
                  $button.appendChild($a);
                }
                $a.appendChild($button);
              }
            }
            $list.appendChild($a);
          }
          $div1.appendChild($list);
        });
      }
      //$div.appendChild($div1);

      /*if (total_count > (options.page * options.limit)) {
        const $more = document.createElement('a');
        $more.classList.add('button','is-fullsize');
        $more.id = 'clickID-recipe_list_more';
        let $name = 'history';
        if (options.viewerSelector === 'babyfoodRecipesMadeListViewer') {
          $name = 'cooked';
        }
        $more.href = "/baby-food-recipe/" + $name + "?page=" + (options.page += 1);
        $more.textContent = 'もっと見る';
        {
          const $more_i = document.createElement('i');
          $more_i.classList.add('babysymbol','babysymbol-arrowdown','fz-i','c-pink','ml10');
          $more.appendChild($more_i);
        }
        $div.appendChild($more);
      }*/
    //}
    return $div1;
  }
}
