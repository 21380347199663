
export {createRecipeCarouselTopBlock};

function createRecipeCarouselTopBlock(data, options) {
  if (data.recipes.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('swiper-wrapper','recipe-list');
    {
      data.recipes.forEach(($row, index) => {
        const $category_name = $row.category_names[1];
        let $category_class = 'm5-6';
        let $category_display = '5～6カ月ごろ';
        if ($category_name === '離乳食中期（7～8カ月ごろ）') {
          $category_class = 'm7-8';
          $category_display = '7～8カ月ごろ';
        } else if ($category_name === '離乳食後期（9～11カ月ごろ）') {
          $category_class = 'm9-11';
          $category_display = '9～11カ月ごろ';
        }else if ($category_name === '離乳食完了期（1歳～1歳6カ月ごろ）') {
          $category_class = 'y1-y1m6';
          $category_display = '1歳～1歳6カ月ごろ';
        }
        const $slide = document.createElement('div');
        $slide.classList.add('swiper-slide','recipe-wrap');
        {
          const $a = document.createElement('a');
          $a.id = 'clickID-recipestop_carousel_' + (index + 1);
          $a.classList.add($category_class, 'gtm-click'); // to change month class based on age;
          $a.href = document.location.origin + '/baby-food-recipe/recipe/' + $row.id + '/?utm_source=recipestop&utm_medium=carousel&utm_campaign=carousel_231019';
          {
            const $figure = document.createElement('figure');
            $figure.classList.add('is-recipe-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img');
              $img.src = $row.photo;
              $img.alt = $row.title;
              $img.loading = 'lazy';
              $img.style.width = '100%';
              $img.style.height = 'auto';
              $figure.appendChild($img);
            }
            $a.appendChild($figure);

            const $title_div = document.createElement('div');
            $title_div.classList.add('is-recipe-info','icon-circle');
            {
              const $title = document.createElement('div');
              $title.classList.add('is-recipe-title','line-clamp','is-1');
              {
                const $span1 = document.createElement('span');
                $span1.classList.add('color');
                $span1.textContent =  $category_display;
                $title.appendChild($span1);

                const $p1 = document.createElement('span');
                $p1.textContent = $row.title;
                $title.appendChild($p1);
              }
              $title_div.appendChild($title);
            }
            $a.appendChild($title_div);
          }
          $slide.appendChild($a);
        }
        $div.appendChild($slide);
      });
    }
    return $div;
  }
}
