
export {createPaginatorBlock};

function createPaginatorBlock(el,url,total,current) {
  const $ul = document.createElement('ul');
  $ul.classList.add('row', 'center-xs', 'pagination-pages');
  {
    if (total <= 5) {
      for (let i = 1; i <= total ; i++) {
        const $page = document.createElement('li');
        if (i == current) {
          $page.classList.add('is-current');
        }
        {
          const $a = document.createElement('a');
          $a.classList.add('current');
          $a.href = url + i;
          $a.textContent = i;
          $page.appendChild($a);
        }
        $ul.appendChild($page);
      }
    }else {
      if ( (current != 1) && (current != 2)) {
        const $first = document.createElement('li');
        $first.classList.add('first');
        {
          const $a = document.createElement('a');
          $a.href = url + 1;
          {
            const $span = document.createElement('span');
            $span.classList.add('babysymbol', 'babysymbol-fastreverse');
            $a.appendChild($span);
          }
          $first.appendChild($a);
        }
        $ul.appendChild($first);
        const $prev = document.createElement('li');
        $prev.classList.add('prev');
        {
          const $a = document.createElement('a');
          $a.href = url + (current - 1);
          {
            const $span = document.createElement('span');
            $span.classList.add('babysymbol', 'babysymbol-browserback');
            $a.appendChild($span);
          }
          $prev.appendChild($a);
        }
        $ul.appendChild($prev);
        if ( current > 3) {
          const $dot = document.createElement('li');
          $dot.classList.add('dot');
          {
            const $span = document.createElement('span');
            $span.textContent = '…';
            $dot.appendChild($span);
          }
          $ul.appendChild($dot);
        }
      }
      if ( (current == 1) || (current == 2)) {
        for (let i = 1; i <= 5 ; i++) {
          const $page = document.createElement('li');
          if (i == current) {
            $page.classList.add('is-current');
          }
          {
            const $a = document.createElement('a');
            $a.classList.add('current');
            $a.href = url + i;
            $a.textContent = i;
            $page.appendChild($a);
          }
          $ul.appendChild($page);
        }
      }else if ( (current > 2) && (current < (total-2))) {
        let start = current - 2;
        let end = current + 2;
        for (let i = start; i <= end ; i++) {
          const $page = document.createElement('li');
          if (i == current) {
            $page.classList.add('is-current');
          }
          {
            const $a = document.createElement('a');
            $a.classList.add('current');
            $a.href = url + i;
            $a.textContent = i;
            $page.appendChild($a);
          }
          $ul.appendChild($page);
        }
      }else if (current >= (total-2)) {
        let end = total;
        let start = total - 5;
        for (let i = start; i <= end ; i++) {
          const $page = document.createElement('li');
          if (i == current) {
            $page.classList.add('is-current');
          }
          {
            const $a = document.createElement('a');
            $a.classList.add('current');
            $a.href = url + i;
            $a.textContent = i;
            $page.appendChild($a);
          }
          $ul.appendChild($page);
        }
      }

      if ( (current < (total - 2))) {
        const $dot = document.createElement('li');
        $dot.classList.add('dot');
        {
          const $span = document.createElement('span');
          $span.textContent = '…';
          $dot.appendChild($span);
        }
        $ul.appendChild($dot);
        const $next = document.createElement('li');
        $next.classList.add('next');
        {
          const $a = document.createElement('a');
          $a.href = url + (current + 1);
          {
            const $span = document.createElement('span');
            $span.classList.add('babysymbol', 'babysymbol-browsernext');
            $a.appendChild($span);
          }
          $next.appendChild($a);
        }
        $ul.appendChild($next);
        const $last = document.createElement('li');
        $last.classList.add('last');
        {
          const $a = document.createElement('a');
          $a.href = url + total;
          {
            const $span = document.createElement('span');
            $span.classList.add('babysymbol', 'babysymbol-fastforward');
            $a.appendChild($span);
          }
          $last.appendChild($a);
        }
        $ul.appendChild($last);
      }
    }
  }
  return $ul;
}
