
export {createBabyfoodRecipesMovieBlock};

function createBabyfoodRecipesMovieBlock(data, options) {
  if (data.recipes.length > 0) {
    const $div = document.createElement('div');
    {
      data.recipes.forEach(($row, index) => {
        const $list_div = document.createElement('div');
        $list_div.classList.add('movie-wrap');
        {
          const $a = document.createElement('a');
          $a.href = document.location.origin + '/baby-food-recipe/recipe/' + $row.id;
          {
            const $div1 = document.createElement('div');
            $div1.classList.add('row', 'middle-xs');
            {
              const $div2 = document.createElement('div');
              $div2.classList.add('is-movie-trim', 'col-xs-6', 'col-md-4');
              {
                const $img = document.createElement('img');
                $img.src = 'https://img.youtube.com/vi/' + $row.youtube_id + '/mqdefault.jpg';
                $img.alt = $row.title;
                $img.loading = 'lazy';
                $img.style.width = '100%';
                $img.style.height = 'auto';
                $div2.appendChild($img);
              }
              $div1.appendChild($div2);

              const $div3 = document.createElement('div');
              $div3.classList.add('is-movie-title', 'col-xs-6', 'col-md-8');
              {
                const $icon = document.createElement('i');
                $icon.classList.add('babysymbol', 'babysymbol-youtube', 'color');
                $div3.appendChild($icon);

                //$div3.textContent = $row.title;
                const $span_text = document.createElement('span');
                $span_text.textContent = $row.title;
                $div3.appendChild($span_text);
              }
              $div1.appendChild($div3);
            }
            $a.appendChild($div1);
          }
          $list_div.appendChild($a);
        }
        $div.appendChild($list_div);
      });
    }
    return $div;
  }
}
