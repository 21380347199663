export {setTempCountDown};
function setTempCountDown(){

  const TempCountDown = document.getElementById('js-sec-count-down');
  const scrollView = document.getElementById('modal_scrollView');
  const scrollViewWrap = document.getElementById('modal_scrollView_wrap');

  if (scrollView.classList.contains('is-active') && !scrollViewWrap.classList.contains('is-active') && TempCountDown) {
    const link = TempCountDown.dataset.link;
    const now = new Date();
    let start = now.getSeconds();
    const goal = start + 5;

    let count;
    function tempCountDown(goal) {
      const left = goal - start;
      if (left > 0) {
        start += 1;
        count = { sec: left };
      } else {
        count = { sec: 0 };
      }
      return count;
    }
    //Timer処理
    function setTempCountDown() {
      let counter = tempCountDown(goal);
      let end = 0;
      const countDownTimer = setTimeout(setTempCountDown, 1000);
      Array.prototype.slice.call(document.querySelectorAll('.js-stop-timer'), 0).forEach(el => {
        el.addEventListener('click', () => {
          clearTimeout(countDownTimer);
          return;
        });
      });
      for (let item in counter) {
        document.getElementById(item).textContent = counter[item];
        end += parseInt(counter[item]);
      }
      if (end === 0) {
        clearTimeout(countDownTimer);
        window.location.href = link;
      }
    }
    setTempCountDown();
  }

}

