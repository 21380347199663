import {like} from "../like";

export {createLikeBlock};

function createLikeBlock(liked, options) {
  const $button = document.createElement('div');
  $button.classList.add('btn-iine','js-iine');
  $button.dataset.likeUrl = options.like_url;
  $button.dataset.likeAuth = options.like_auth;
  $button.dataset.likeBody = options.like_body;
  {
    const $span = document.createElement('div');
    $span.classList.add('is-text');
    {
      const $icon = document.createElement('span');
      $icon.classList.add('babysymbol', 'fz-i', 'mr10');
      if (liked) {
        $button.style.cursor = 'default';
        $span.classList.add('bgc-pink', 'c-white');
        $icon.classList.add('babysymbol-heart');
        $icon.style.transform = 'scale(1.3, 1.3)';
      } else {
        $button.classList.add('gtm-nice-2');
        $icon.classList.add('babysymbol-heartblank');
      }
      $span.appendChild($icon);
    }
    {
      const $text = document.createElement('span');
      $text.textContent = 'いいね！';
      $span.appendChild($text);
    }
    $button.appendChild($span);

    {
      const $span = document.createElement('div');
      $span.classList.add('is-num');
      {
        const $num = document.createElement('span');
        $num.textContent = options.likes_count;
        $span.appendChild($num);
      }
      $button.appendChild($span);
      like([$button]);
    }
  }
  return $button;
}
