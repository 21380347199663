import '../sass/Frontend/style.sass';
import {scrollToTop} from "./modules/scrollToTop";
import {toggleActive} from "./modules/toggleActive";
import {getResources} from "./modules/getResources";
import lozad from "lozad";
import 'flatpickr/src/style/flatpickr.styl'
import flatpickr from 'flatpickr'
import Japanese from 'flatpickr/dist/l10n/ja'
import Swiper from 'swiper/bundle';
import {Navigation, Pagination} from 'swiper';
import {fallbackCustomScheme} from "./modules/fallbackCustomScheme";
import "./modules/clip";
import "./modules/accordion";
import "./modules/tab";
import {floatingToApp} from "./modules/floating_btn-toapp";
import "./modules/newsComment";
import {setActiveWeek} from "./modules/setActiveWeek";
import {setActiveMonth} from "./modules/setActiveMonth";
import {searchResources} from "./modules/searchResources";
import {swipeGalleryWithThumbs} from "./modules/swipeGalleryWithThumbs";
import {clipKnowledge} from "./modules/clipKnowledge";
import {modalHelpfulReport} from "./modules/modalHelpfulReport";
import {shareSns} from "./modules/shareSns";
import {setPagination} from "./modules/setPagination";
import createPhotosUploader from "./modules/MultiPhotosUploader";
import {previewFormFields} from "./modules/previewFormFields";
import {postQuestion} from "./modules/postQuestion";
import {postNewsComment} from "./modules/postNewsComment";
import {grid} from "./modules/grid";
import {clipNews} from "./modules/clipNews";
import {postNewsCommentReaction} from "./modules/postNewsCommentReaction";
import {modalCommentReport} from "./modules/modalCommentReport";
import {setReportTarget} from "./modules/setReportTarget";
import {hideNewsThumb} from "./modules/hideNewsThumb";
import {setNewsPagination} from "./modules/setNewsPagination";
import {selectIndexNewsSeriesTag} from "./modules/selectIndexNewsSeriesTag";
import {transferResources} from "./modules/transferResources";
import {modalCommentDelete} from "./modules/modalCommentDelete";
import {setDeleteTarget} from "./modules/setDeleteTarget";
import "./modules/countDown";
import "./modules/scrollViewPopup";
import {setTempCountDown} from "./modules/tempCountDown";
import {readFullComment} from "./modules/readFullComment";
import {announe} from "./modules/announe";
import {clipRecipe} from "./modules/clipRecipe";
import {madeRecipe} from "./modules/madeRecipe";

var adOffset, adSize, winH;

document.addEventListener('DOMContentLoaded', () => {

  toggleActive(Array.prototype.slice.call(document.querySelectorAll('.js-toggle-active'), 0));
  floatingToApp(Array.prototype.slice.call(document.querySelectorAll('.floating_btn-close-icon'), 0))

  let message = document.querySelector('.js-floating');

  let last_known_scroll_position = 0;
  let ticking = false;
  window.addEventListener('scroll', function (e) {
    last_known_scroll_position = window.scrollY;
    let reaction_block = document.querySelector('.reaction_block');
    if (reaction_block) {
      adOffset = reaction_block.offsetTop;
    }
    if (!ticking) {
      window.requestAnimationFrame(function () {
        const $top = document.getElementById('totop');
        const $news_clip_side = document.getElementById('clipDetail');
        const $footer = document.getElementById('default-footer');
        const $navbar = document.getElementById('navbar');

        if (last_known_scroll_position >= 200) {
          if ($news_clip_side) {
            $news_clip_side.classList.add('is-in');
          }
        } else {
          if ($news_clip_side && $news_clip_side.classList.contains('is-in')) {
            $news_clip_side.classList.remove('is-in');
          }
        }

        if (last_known_scroll_position > 100) {
          let $overlayAd = document.getElementById('ats-custom-overlay-wrapper');
          if (!$overlayAd) {
            $overlayAd = document.getElementById('ats-overlay_bottom-1');
          }
          if (!$overlayAd) {
            $overlayAd = document.getElementById('ats-overlay_bottom-2');
          }
          if (!$overlayAd) {
            $overlayAd = document.getElementsByClassName('ats-overlay-bottom-wrapper-rendered')[0];
          }
          if ($overlayAd) {
            $top.style.bottom = Math.max(70, ($overlayAd.offsetHeight + 20)) + 'px';
            if ($news_clip_side) {
              $news_clip_side.style.bottom = Math.max(120, ($overlayAd.offsetHeight + 20 + 50)) + 'px';
            }
            //$footer.style.marginBottom = $overlayAd.offsetHeight - 20 + 'px';
            $navbar.style.marginBottom = $overlayAd.offsetHeight + 'px';
          } else {
            $top.style.bottom = '70px';
            if ($news_clip_side) {
              $news_clip_side.style.bottom = '120px';
            }
          }
          $top.classList.add('ease-in');
        } else {
          if ($top.classList.contains('ease-in') && !$top.classList.contains('ease-out')) {
            $top.classList.add('ease-out');
          }
        }
        if (message && document.cookie.replace(/(?:(?:^|.*;\s*)news_app_ad\s*\=\s*([^;]*).*$)|^.*$/, '$1').length == 0) {
          if (last_known_scroll_position > adOffset - winH) {
            if (message.style.display === 'block' && !message.classList.contains('is-animated')) {
              message.classList.add('is-animated');
              message.animate([{ opacity: '1' }, { opacity: '0' }], 250)
              setTimeout(function () {
                message.classList.remove('is-animated');
                message.style.display = 'none';
              }, 250);
            }
          } else if (last_known_scroll_position > 70) {
            if (message.style.display === 'none' && !message.classList.contains('is-animated')) {
              message.classList.add('is-animated');
              message.style.display = 'block';
              message.animate([{ opacity: '0' }, { opacity: '1' }], 250);
              setTimeout(function () {
                message.classList.remove('is-animated');
              }, 250);
            }
          } else {
            if (message.style.display === 'block' && !message.classList.contains('is-animated')) {
              message.classList.add('is-animated');
              message.animate([{ opacity: '1' }, { opacity: '0' }], 250)
              setTimeout(function () {
                message.classList.remove('is-animated');
                message.style.display = 'none';
              }, 250);
            }
          }
        }

        ticking = false;
      });
    }
    ticking = true;
  });
  scrollToTop(Array.prototype.slice.call(document.querySelectorAll('.js-scroll-to-top'), 0));

  Array.prototype.slice.call(document.querySelectorAll('.modal'), 0).forEach($modal => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (document.querySelector('.modal.is-active')) {
          document.body.classList.add('html-clipped');
          setTempCountDown();
        } else {
          document.body.classList.remove('html-clipped');
        }
      });
    });
    observer.observe($modal, { attributes: true });
  });

  if (document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1').length > 0) {
    Array.prototype.slice.call(document.querySelectorAll('.js-member-only'), 0).forEach($el => {
      $el.classList.remove('is-hidden');
    });
  } else {
    Array.prototype.slice.call(document.querySelectorAll('.js-nonmember-only'), 0).forEach($el => {
      $el.classList.remove('is-hidden');
    });
  }

  getResources(Array.prototype.slice.call(document.querySelectorAll('.js-get-resources'), 0));

  const observer = lozad();
  observer.observe();

  Array.prototype.slice.call(document.querySelectorAll('.js-flatpickr'), 0).forEach($element => {
    const options = JSON.parse($element.dataset.flatpickrOptions);
    options.locale = options.locale || Japanese.ja;
    flatpickr($element, options);
  });

  Swiper.use([Navigation, Pagination]);
  const swiper = new Swiper(".js-ss-swiper", {
    slidesPerView: 1.3,
    centeredSlides: !0,
    spaceBetween: 20,
    autoHeight: !0,
    breakpoints: { 640: { slidesPerView: 2 } },
    pagination: {
      el: ".swiper-pagination", clickable: !0, renderBullet: function (e, t) {
        return '<span class="' + t + '">' + (e + 1) + "</span>"
      }
    }
  })


  const canvas = document.getElementById('qrcode');
  if (canvas) {
    const QRCode = require('qrcode');
    const options = {
      width: 230,
      margin: 0,
      padding: 0,
    };
    const code = document.getElementById('code').value;
    QRCode.toCanvas(canvas, code, options, function (error) {
      if (error) console.error(error)
    });
  }

  Array.prototype.slice.call(document.querySelectorAll('.js-open-with-app'), 0).forEach($element => {
    $element.addEventListener('click', () => {
      fallbackCustomScheme($element.dataset.default);
    });
  });

  Array.prototype.slice.call(document.querySelectorAll('.js-hospitalGeoBtn'), 0).forEach($element => {
    $element.addEventListener('click', () => {
      let action = $element.dataset.action;
      // 現在地から探す
      navigator.geolocation.getCurrentPosition(function (position) {
        // 現在位置が取得できた場合
        location.href = action + '?lat=' + position.coords.latitude + '&lon=' + position.coords.longitude;
      }, function () {
        // 現在位置が取得できなかった場合
        alert('現在位置が取得できませんでした');
      }, {
        "enableHighAccuracy": false,
        "timeout": 10000
      });
    });
  });

  let selectors;

  selectors = '.js-upload-photos';
  createPhotosUploader(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-preview-form-fields';
  previewFormFields(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-post-question';
  postQuestion(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-pregnancy-week';
  setActiveWeek(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-birth-month';
  setActiveMonth(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-clip-knowledge';
  clipKnowledge(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-post-helpful';
  modalHelpfulReport(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-clip-news';
  clipNews(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-hide-thumb';
  hideNewsThumb(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-share-sns';
  shareSns(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-set-pagination'
  setPagination(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-set-news-pagination'
  setNewsPagination(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-post-news-comment'
  postNewsComment(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-post-news-comment-reaction'
  postNewsCommentReaction(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-modal-comment-report'
  modalCommentReport(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-set-report-target'
  setReportTarget(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-select-index-news-series-tag'
  selectIndexNewsSeriesTag(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-transfer-resources';
  transferResources(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-modal-comment-delete'
  modalCommentDelete(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-set-delete-target'
  setDeleteTarget(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-btn-readmore';
  readFullComment(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-clip-recipe';
  clipRecipe(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-made-recipe';
  madeRecipe(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  setTimeout(function () { grid() }, 5000);
});

window.addEventListener('popstate', () => {
  sessionStorage.setItem('popstate', '1'); // ブラウザバック実行済み判定をセット（safari用）
});

window.addEventListener('load', (event) => {
  if (document.querySelector('.l-footer')) {
    adOffset = document.querySelector('.l-footer').offsetTop;
  } else {
    adOffset = 0;
  }
  winH = window.innerHeight;

  document.body.dataset.state = JSON.stringify(history.state);

  // ニューストップ swiper
  const newsTopFixedCarousel = new Swiper('.js-news-top-fixed-carousel', {
    centeredSlides: true,
    nested: true,
    loop: true,
    slidesPerView: 1,
    slideActiveClass: 'swiper-slide-active',
    spaceBetween: 20,
    breakpoints: {
      640: {
        slidesPerView: 1.5,
      }
    },
    pagination: {
      el: '.news-top-fixed-carousel-pagination',
    },
    autoplay: {
      delay: 7000,
    },
    on: {
      activeIndexChange: function (swiper) {
        const a = swiper.slides[swiper.activeIndex].querySelector('a');
        a && dataLayer.push({
          'event': 'gtm-event-impression',
          'custom_page_view': a.href,
        });
      },
    },
  });

  // recipe top swiper
  const RecipeFixedCarousel = new Swiper('.recipe-fixed-carousel', {
    // slidesPerView: 'auto',
    centeredSlides: true,
    nested: true,
    loop: true,
    slidesPerView: 1,
    slideActiveClass: 'swiper-slide-active',
    spaceBetween: 15,

    breakpoints: {
      640: {
        slidesPerView: 1.5,
      },
    },

    pagination: {
      //ページネーション（ドット）
      el: '.recipe-fixed-carousel-pagination',
    },
    autoplay: {
      delay: 3000, // 3秒ごとにスライドが切り替わる
      disableOnInteraction: false, // ユーザー操作後も自動スライドを続ける
    },
  });
  // recipe swiper
  var swiperRecipe = new Swiper('.recipe-swiper', {
    slidesPerView: 2.5,
    nested: true,
    loop: true,
    autoHeight: false,
    mousewheel: true,
    spaceBetween: 10,
    slideActiveClass: 'swiper-slide-active',
    breakpoints: {
      640: {
        slidesPerView: 5.3,
      },
    },
    observer: true,
    observeParents: true,
  });
  var swiperM56 = new Swiper('.recipe-list-m5-6 > .recipe-swiper', {
    slidesPerView: 2.5,
    nested: true,
    loop: true,
    autoHeight: false,
    mousewheel: true,
    spaceBetween: 10,
    slideActiveClass: 'swiper-slide-active',
    breakpoints: {
      640: {
        slidesPerView: 5.3,
      },
    },
    observer: true,
    observeParents: true,
  });
  var swiperM78 = new Swiper('.recipe-list-m7-8 > .recipe-swiper', {
    slidesPerView: 2.5,
    nested: true,
    loop: true,
    autoHeight: false,
    mousewheel: true,
    spaceBetween: 10,
    slideActiveClass: 'swiper-slide-active',
    breakpoints: {
      640: {
        slidesPerView: 5.3,
      },
    },
    observer: true,
    observeParents: true,
  });
  var swiperM911 = new Swiper('.recipe-list-m9-11 > .recipe-swiper', {
    slidesPerView: 2.5,
    nested: true,
    loop: true,
    autoHeight: false,
    mousewheel: true,
    spaceBetween: 10,
    slideActiveClass: 'swiper-slide-active',
    breakpoints: {
      640: {
        slidesPerView: 5.3,
      },
    },
    observer: true,
    observeParents: true,
  });
  var swiperY1Y1M6 = new Swiper('.recipe-list-y1-y1m6 > .recipe-swiper', {
    slidesPerView: 2.5,
    nested: true,
    loop: true,
    autoHeight: false,
    mousewheel: true,
    spaceBetween: 10,
    slideActiveClass: 'swiper-slide-active',
    breakpoints: {
      640: {
        slidesPerView: 5.3,
      },
    },
    observer: true,
    observeParents: true,
  });

  let selectors;
  selectors = '.js-swiper';
  Array.prototype.slice.call(document.querySelectorAll(selectors), 0).forEach(
    el => {
      new Swiper(el, JSON.parse(el.dataset.swiperOptions))
    });

  selectors = '.js-knowledge-swiper-contents, .js-news-swiper-contents, .js-news-popular-swiper-contents, .js-news-keywords-swiper-contents';
  swipeGalleryWithThumbs(Array.prototype.slice.call(document.querySelectorAll(selectors), 0)).then((swipers) => {
    selectors = '.js-search-resources';
    searchResources(Array.prototype.slice.call(document.querySelectorAll(selectors), 0)).then((lazy) => {
      sessionStorage.setItem('load', location.pathname);
      swipers.forEach(swiper => {

        const tabs = document.getElementsByClassName('tab');
        for (let i = 0; i < tabs.length; i++) {
          tabs[i].addEventListener('click', () => {
            swiper.updateAutoHeight();
          });
        }

        swiper.on('slideChangeTransitionEnd', () => {
          sessionStorage.setItem('load', location.pathname);
          changeUrl();
          if (lazy !== {}) {
            const slides = swiper.el.querySelectorAll('.swiper-slide[data-history]');
            const tabName = slides[swiper.activeIndex].dataset.history;
            if (tabName in lazy && lazy[tabName].length > 0) {
              const allFunc = lazy[tabName];
              lazy[tabName] = [];
              allFunc.forEach(func => func());
            }
          }
        });
      });
    });
  });
});

// リサイズ時に行う処理
window.addEventListener('resize', function () {
  if (document.querySelector('.l-footer')) {
    adOffset = document.querySelector('.l-footer').offsetTop;
  } else {
    adOffset = 0;
  }
  winH = window.innerHeight;
}, false);

// Safari bfcache
window.addEventListener('pageshow', function (event) {
  if (event.persisted) {
    const $clipimg = document.getElementById('clip_notification');
    if ($clipimg) {
      $clipimg.classList.remove('is-on');
      $clipimg.classList.remove('is-off');
    }
  }
});

// アナウンス枠の高さ調整
document.addEventListener('DOMContentLoaded', () => {
  announe();
  Array.prototype.slice.call(document.querySelectorAll('.btn_news_digest'), 0).forEach(el => {
    el.addEventListener('click', () => {
      let news_iframe = document.getElementById('news_announce_iframe');
      if (news_iframe) {
        news_iframe.style.height = '0px';
        news_iframe.style.height = news_iframe.contentWindow.document.documentElement.scrollHeight + 10 + 'px';
      }
    });
  });
});
