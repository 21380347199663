export {announe};

function announe(){
  Array.prototype.slice.call(document.querySelectorAll('.js-announce-iframe'), 0).forEach(iframe => {
    iframe.addEventListener('load', function() {
      if(iframe && (iframe.contentWindow.document.body.innerHTML.trim() !== '')) {
        iframe.style.height = '0px';
        iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 10 + 'px';
      }else {
        iframe.style.display = 'none';
      }
    });
  });
}
