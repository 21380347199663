import createAPI from './APIRequest';
import {createLikeBlock} from "./elementCreators/createLikeBlock";
import {createLikeTopBlock} from "./elementCreators/createLikeTopBlock";
import {createLikeNewsTopBlock} from "./elementCreators/createLikeNewsTopBlock";
import {createNewsCarouselTopBlock} from "./elementCreators/createNewsCarouselTopBlock";
import {createClipNewsTopBlock} from './elementCreators/createClipNewsTopBlock';
import {createClipNewsBottomBlock} from './elementCreators/createClipNewsBottomBlock';
import {createClipNewsSideBlock} from './elementCreators/createClipNewsSideBlock';
import {createClipCategoryNewsBlock} from './elementCreators/createClipCategoryNewsBlock';
import {createClipAuthorBlock} from './elementCreators/createClipAuthorBlock';
import {createClipNewsSeriesBlock} from './elementCreators/createClipNewsSeriesBlock';
import {createRecipeCarouselTopBlock} from "./elementCreators/createRecipeCarouselTopBlock";
import {createBabyfoodRecipesBlock} from "./elementCreators/createBabyfoodRecipesBlock";
import {clipRecipe} from "./clipRecipe";
import {createBabyfoodRecipesMovieBlock} from "./elementCreators/createBabyfoodRecipesMovieBlock";
import {createBabyfoodRecipesList} from "./elementCreators/createBabyfoodRecipesList";

export {getResources};

function render($viewer, response, options) {
  if (options.element === 'like') {
    $viewer.appendChild(createLikeBlock(response.total_count ? true : false, options));
  } else if (options.element === 'likeTop') {
    $viewer.appendChild(createLikeTopBlock(response.total_count ? true : false, options));
  } else if (options.element === 'likeNewsTop') {
    $viewer.appendChild(createLikeNewsTopBlock(response.total_count ? true : false, options));
  } else if (options.element === 'carouselTop') {
    if (response && response.data) {
      $viewer.appendChild(createNewsCarouselTopBlock(response.data, options));
      const $pagination = document.createElement('div');
      $pagination.classList.add('news-top-fixed-carousel-pagination');
      $viewer.appendChild($pagination);
    }
  } else if (options.element === 'clipNewsTop') {
    $viewer.appendChild(createClipNewsTopBlock(response.total_count ? true : false, options));
  } else if (options.element === 'clipNewsBottom') {
    $viewer.appendChild(createClipNewsBottomBlock(response.total_count ? true : false, options));
  } else if (options.element === 'clipNewsSide') {
    $viewer.appendChild(createClipNewsSideBlock(response.total_count ? true : false, options));
  } else if (options.element === 'clipCategoryNews') {
    $viewer.appendChild(createClipCategoryNewsBlock(response.total_count ? true : false, options));
  } else if (options.element === 'clipAuthor1' || options.element === 'clipAuthor2') {
    $viewer.appendChild(createClipAuthorBlock(response.total_count ? true : false, options, options.element));
  } else if (options.element === 'clipNewsSeries1' || options.element === 'clipNewsSeries2' || options.element === 'clipNewsSeries3') {
    $viewer.appendChild(createClipNewsSeriesBlock(response.total_count ? true : false, options, options.element));
  } else if (options.element === 'newsCommentReactions') {
    $viewer.querySelectorAll('.' + options.strip_class_name).forEach(el => (response.data.includes(parseInt(el.dataset.comment_id)) || el.classList.remove(options.strip_class_name)));
  } else if (options.element === 'recipeCarouselTop') {
    if (response && response.data) {
      $viewer.appendChild(createRecipeCarouselTopBlock(response.data, options));
      const $pagination = document.createElement('div');
      $pagination.classList.add('recipe-fixed-carousel-pagination');
      $viewer.appendChild($pagination);
    }
  } else if (options.element === 'babyfoodRecipes') {
    if (response.data.total_count != 0) {
      console.log('response.data.total_count != 0 ');
      $viewer.appendChild(createBabyfoodRecipesBlock(response.data, options));
      const selectors = '.js-clip-recipe';
      clipRecipe(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
    }else {
      $viewer.classList.remove('swiper');
      $viewer.classList.remove('swiper-container');
      $viewer.classList.remove('recipe-swiper');
    }
  } else if (options.element === 'babyfoodRecipesMovie') {
    if (response.data.total_count != 0) {
      $viewer.appendChild(createBabyfoodRecipesMovieBlock(response.data, options));
    }
  } else if (options.element === 'babyfoodRecipesMovieList') {
    const $movie_list = $viewer.querySelectorAll('.index-recipes-movie-list');
    $movie_list[0].appendChild(createBabyfoodRecipesMovieBlock(response.data, options));
    if (response.data.total_count <= ($viewer.dataset.resourcesPage * parseInt($viewer.dataset.resourcesLimit))) {
      const $see_more = $viewer.querySelector('.more-get-results');
      $see_more.classList.add('is-hidden');
    }
  }else if (options.element === 'babyfoodRecipesList') {
    /*if (response.data.total_count != 0) {
      $viewer.appendChild(createBabyfoodRecipesList(response.data, options));
    }*/
    if (response.data.total_count != 0) {
      let total = document.getElementById('totalCount');
      total.textContent = response.data.total_count;
      const $recipe_list = $viewer.querySelectorAll('.index-recipes-list');
      $recipe_list[0].appendChild(createBabyfoodRecipesList(response.data, response.data.total_count, options));
      if (response.data.total_count <= ($viewer.dataset.resourcesPage * parseInt($viewer.dataset.resourcesLimit))) {
        const $see_more = $viewer.querySelector('.more-get-results');
        $see_more.classList.add('is-hidden');
      }
    }

  }
}

function _getResources(el) {
  let resourcesUrl = el.dataset.resourcesUrl;
  if (el.dataset.resourcesLimit && el.dataset.resourcesPage) {
    resourcesUrl = el.dataset.resourcesUrl + '&limit=' + el.dataset.resourcesLimit + '&page=' + el.dataset.resourcesPage;
  }
  createAPI().send(
    resourcesUrl,
    'GET',
    el.dataset.resourcesAuth,
    null,
    function() {},
    function (response) {
      render(el, response, JSON.parse(el.dataset.renderOptions));
    }
  );
}
function clickMore(event,el){
  event.preventDefault();
  event.currentTarget.blur();
  el.dataset.resourcesPage = parseInt(el.dataset.resourcesPage) + 1;
  let url = location.pathname;
  if (url.includes('/page')) {
    url = url.substring(0, url.indexOf('/page')).trim();
  }
  const query_string = window.location.search;
  console.log(query_string);
  const value = el.dataset.resourcesPage;
  history.replaceState({value: value}, null,  url  +"/page" + value + (query_string.trim() ? query_string : ''));
  _getResources(el);
}
async function getResources(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      const $more = el.querySelector('.more-get-results');
      $more && $more.addEventListener('click', (event) => clickMore(event,el));
      if (el.classList.contains('js-member-only') && document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1').length <= 0) {
        // ログインしていない場合は実行不要
      } else {
        _getResources(el);
      }
    });
  }
}
