import createAPI from './APIRequest';

export {clipNewsSide};

const API = createAPI();
function _clipNews(el) {
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    const $clipimg = document.getElementById('clip_notification');
    const $bottom = document.querySelector('.js-clip-news-bottom');
    const $top = document.querySelector('.js-clip-news-top');
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        $clipimg.classList.remove('is-on');
        $clipimg.classList.remove('is-off');
      },
      (response) => {
        if ($current.dataset.clipMethod === 'POST') {
          updatePost($clipimg,$current,$bottom,$top,response.total_count);
        } else {
          updateDelete($clipimg,$current,$bottom,$top,response.total_count);
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        }else if(status === 422) {
          //既にクリップしていたら
          if(response.data['news_id'] && response.data['news_id']['duplicateError']){
            updatePost($clipimg,$current,$bottom,$top,null);
          }
        }else if(status === 404 && $current.dataset.clipMethod === 'DELETE') {
          updateDelete($clipimg,$current,$bottom,$top,null);
        }
      }
    );
  });
}

function updatePost($clipimg,$current,$bottom,$top,total_count){
  // ポップアップCSSアニメーション開始
  $clipimg.classList.remove('is-off');
  $clipimg.classList.add('is-on');
  // dataset更新
  $current.dataset.clipMethod = 'DELETE';
  {
    const $sideOn = document.getElementById('clipDetail');
    $sideOn && $sideOn.classList.add('is-on');
  }

  if($top) {
    $top.dataset.clipMethod = 'DELETE';
    if($top.querySelector('.clips-counter') && total_count !== null) {
      $top.querySelector('.clips-counter').textContent = total_count;
    }
    $top.querySelector('i.babysymbol-clip').classList.remove('c-gray');
    $top.querySelector('i.babysymbol-clip').classList.add('c-pink');
  }

  if($bottom) {
    $bottom.dataset.clipMethod = 'DELETE';
    const $babysymbol = $bottom.querySelector('.babysymbol');

    if($bottom.querySelector('.clips-counter') && total_count !== null) {
      $bottom.querySelector('.clips-counter').textContent = total_count;
    }

    if($babysymbol){
      $babysymbol.classList.add('c-pink');
      $babysymbol.classList.remove('c-gray');
      $babysymbol.style.color = null;
    }
  }
}

function updateDelete($clipimg,$current,$bottom,$top,total_count){
  // ポップアップCSSアニメーション開始
  $clipimg.classList.remove('is-on');
  $clipimg.classList.add('is-off');
  // dataset更新
  $current.dataset.clipMethod = 'POST';
  {
    const $sideOn = document.getElementById('clipDetail');
    $sideOn && $sideOn.classList.remove('is-on');
  }

  if($top) {
    $top.dataset.clipMethod = 'POST';
    if($top.querySelector('.clips-counter') && total_count !== null) {
      $top.querySelector('.clips-counter').textContent = total_count;
    }
    $top.querySelector('i.babysymbol-clip').classList.remove('c-pink');
    $top.querySelector('i.babysymbol-clip').classList.add('c-gray');
  }

  if($bottom) {
    $bottom.dataset.clipMethod = 'POST';
    const $babysymbol = $bottom.querySelector('.babysymbol');

    if($bottom.querySelector('.clips-counter') && total_count !== null) {
      $bottom.querySelector('.clips-counter').textContent = total_count;
    }

    if($babysymbol){
      $babysymbol.classList.remove('c-pink');
      $babysymbol.classList.add('c-gray');
      $babysymbol.style.color = null;
    }
  }

}

async function clipNewsSide(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipNews(el));
  }
}
