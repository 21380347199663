// いいね-記事内

let iine_article = document.getElementsByClassName('js-iine');
for(let i = 0; i < iine_article.length; i++) {
  iine_article[i].addEventListener(
    'click',
    function () {
      const bb = this.querySelector('.babysymbol');
      this.classList.toggle('is-on');
      bb.classList.add('babysymbol-heart')
    },
    { once: true }
  );
}



// clip
const clip = document.getElementsByClassName('js-clip');
for(let i = 0; i < clip.length; i++) {
  clip[i].addEventListener('click', clipSwitch);
}

function clipSwitch(){
  this.classList.toggle('is-on');
  let clipimg = document.getElementById('clip_notification');

  if(
    this.classList.contains('is-on') == true ){
      clipimg.classList.add('is-on');
      clipimg.classList.remove('is-off');

    }
    else{
      clipimg.classList.remove('is-on');
      clipimg.classList.add('is-off');
    }

};

