import createAPI from './APIRequest';
import {getResources} from "./getResources";

export {likeTop};

function removeAnimationClass(event) {
  // CSSアニメーションが終了したら必要ないのでクラスを削除する
  if (event.animationName === 'opacity01Anime') {
    this.dataset.animation1End = 'done';
  } else if (event.animationName === 'heartAnime') {
    this.dataset.animation2End = 'done';
  } else if (event.animationName === 'jumpAnime') {
    this.dataset.animation3End = 'done';
  }
  if (this.dataset.animation1End && this.dataset.animation2End && this.dataset.animation3End) {
    this.style.cursor = 'default';
    this.classList.remove('is-on');
  }
}

const API = createAPI();
function _like(event) {
  if (this.querySelector('.babysymbol').classList.contains('babysymbol-heart')) {
    // いいね済み
    return;
  }
  API.send(
    this.dataset.likeUrl,
    'POST',
    this.dataset.likeAuth,
    this.dataset.likeBody,
    () => {
      this.dataset.animation1End = '';
      this.dataset.animation2End = '';
      this.dataset.animation3End = '';
    },
    (response) => {
      // CSSアニメーション開始
      this.classList.add('is-on');
      this.querySelector('.babysymbol').classList.add('babysymbol-heart');
      this.querySelector('.babysymbol').classList.remove('babysymbol-heartblank');
      // いいね数更新
      this.querySelector('.is-num').textContent = response.total_count;
      const bottom = document.querySelector('.nice-clip-bottom');
      bottom.querySelector('.is-num').textContent = response.total_count;
      bottom.querySelector('.is-text').classList.add('bgc-pink', 'c-white');
      bottom.querySelector('.is-text').querySelector('.babysymbol').classList.remove('babysymbol-heartblank');
      bottom.querySelector('.is-text').querySelector('.babysymbol').classList.add('babysymbol-heart');
    },
    (status, response) => {
      this.querySelector('.label-round').style.opacity = null;
    }
  );
}

async function likeTop(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.addEventListener('animationend', removeAnimationClass);
      el.addEventListener('click', _like);
    });
  }
}
