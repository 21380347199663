import createAPI from './APIRequest';

export {postNewsCommentReaction};

const API = createAPI();
async function postNewsCommentReaction(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      let plus = el.querySelector('.p-comment-rate-plus');
      let minus = el.querySelector('.p-comment-rate-minus');
      let rate = el.querySelector('.p-comment-rate-bar-plus');
      let plustext = el.querySelector('.p-comment-rate-text-plus');
      let minustext = el.querySelector('.p-comment-rate-text-minus');

      onPlus(el,plus,minus,rate,plustext,minustext);

      onMinus(el,plus,minus,rate,plustext,minustext);

    });
  }
}

function onMinus(el,plus,minus,rate,plustext,minustext){
  minus.addEventListener('click',event => {

    if(minus.classList.contains('is-pushed')) return;

    minus.classList.add('is-loading');

    let body = el.dataset.commentReactionBody + '&reaction_type=2';

    API.send(
      el.dataset.commentReactionUrl,
      el.dataset.commentReactionMethod,
      el.dataset.commentReactionAuth,
      body,
      () => {
      },
      (response) => {

        let good_count = response.data.good_count;
        let bad_count = response.data.bad_count;

        plustext.innerHTML = '+' + good_count;
        minustext.innerHTML = '-' + bad_count;

        minus.classList.remove('is-loading');

        plus.classList.add('is-pushed');
        minus.classList.add('is-pushed');

        let calm = 0;
        if(good_count > 0){
          calm = (good_count / (good_count + bad_count)) * 100;
        }
        rate.style.width = calm + '%';
      },
      (status, response) => {
        if (status === 401) {
        } else {
        }
      }
    );
  });
}
function onPlus(el,plus,minus,rate,plustext,minustext){
  plus.addEventListener('click',event => {

    if(plus.classList.contains('is-pushed')) return;

    plus.classList.add('is-loading');

    let body = el.dataset.commentReactionBody + '&reaction_type=1';

    API.send(
      el.dataset.commentReactionUrl,
      el.dataset.commentReactionMethod,
      el.dataset.commentReactionAuth,
      body,
      () => {
      },
      (response) => {

        let good_count = response.data.good_count;
        let bad_count = response.data.bad_count;

        plustext.innerHTML = '+' + good_count;
        minustext.innerHTML = '-' + bad_count;

        plus.classList.remove('is-loading');

        plus.classList.add('is-pushed');
        minus.classList.add('is-pushed');

        let calm = 0;
        if(good_count > 0){
          calm = (good_count / (good_count + bad_count)) * 100;
        }
        rate.style.width = calm + '%';
      },
      (status, response) => {
        if (status === 401) {
        } else {
        }
      }
    );
  });
}
