import createAPI from './APIRequest';

export {clipNewsSeries};

const API = createAPI();
function _clipNewsSeries(el) {
  const $babysymbol = el.querySelector('.babysymbol');
  if($babysymbol) {
    $babysymbol.addEventListener('animationend', (event) => {
      // CSSアニメーションが終了したら必要ないのでクラスを削除する
      el.classList.remove('is-on');
      $babysymbol.classList.add('c-pink');
      $babysymbol.classList.remove('c-gray');
      $babysymbol.style.color = null;
    });
  }
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    $current.classList.toggle('is-on');
    let clipimg = document.getElementById('clip_notification');
    let isNotification = $current.dataset.clipNotification;
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        clipimg.classList.remove('is-on');
        clipimg.classList.remove('is-off');
        $current.classList.add('is-loading');
        if($babysymbol) {
          $babysymbol.classList.remove('babysymbol-clip');
        }
      },
      (response) => {
        $current.classList.remove('is-loading');
        const $series = document.querySelectorAll('.js-clip-news-series');
        if ($current.dataset.clipMethod === 'POST') {
          // ポップアップCSSアニメーション開始
          if(isNotification === '1'){
            clipimg.classList.add('is-on');
            clipimg.classList.remove('is-off');
          }
          $series.forEach($s => {
            const $babysymbol = $s.querySelector('.babysymbol');
            if($babysymbol) {
              $babysymbol.classList.add('babysymbol-clip');
            }

            // dataset更新
            $s.dataset.clipMethod = 'DELETE';
            // カウンター更新
            let $counter = $s.querySelector('.clips-counter');
            if($counter){
              $counter.textContent = response.total_count;
            }
            // アイコンCSSアニメーション開始
            $s.classList.add('is-on');

            if ($s.classList.contains('is-big')) {
              $s.classList.remove('is-primary');
              $s.classList.add('is-disable');
              $s.textContent = 'クリップを解除';
            }
          });
        } else {
          // ポップアップCSSアニメーション開始
          if(isNotification === '1'){
            clipimg.classList.remove('is-on');
            clipimg.classList.add('is-off');
          }
          $series.forEach($s => {
            const $babysymbol = $s.querySelector('.babysymbol');

            if($babysymbol) {
              $babysymbol.classList.add('babysymbol-clip');
              $babysymbol.classList.add('c-gray');
              $babysymbol.classList.remove('c-pink');
              $babysymbol.style.color = null;
            }

            // dataset更新
            $s.dataset.clipMethod = 'POST';
            // カウンター更新
            let $counter = $s.querySelector('.clips-counter');
            if($counter){
              $counter.textContent = response.total_count;
            }
            // html 更新
            $s.classList.remove('is-on');

            if ($s.classList.contains('is-big')) {
              $s.classList.remove('is-disable');
              $s.classList.add('is-primary');
              $s.textContent = 'この連載をクリップ';
            }
          });
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        } else {
          $current.classList.remove('is-loading');
          if($babysymbol) {
            $babysymbol.classList.add('babysymbol-clip');
          }
        }
      }
    );
  });
}

async function clipNewsSeries(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipNewsSeries(el));
  }
}
