export {fallbackCustomScheme};

function fallbackCustomScheme(url) {
  /**
   * https://qiita.com/kwst/items/cb099e175cf414043332
   */
  const _timerId = setTimeout(function() {
    location.href = url;
  }, 500);
  window.focus();
  window.addEventListener('blur', onBlur);
  function onBlur() {
    window.removeEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);
  }
  function onFocus() {
    //console.log('on return browser');
  }
  if (document.hidden !== undefined) {
    document.addEventListener('visibilitychange', onVisibilityChange);
  } else if (document.webkitHidden !== undefined) {
    document.addEventListener('webkitvisibilitychange', onWebkitVisibilityChange);
  }
  function onVisibilityChange() {
    if (!document.hidden) {
      //console.log('on return browser');
    }
  }
  function onWebkitVisibilityChange() {
    if (!document.webkitHidden) {
      //console.log('on return browser');
    }
  }
  let rafTimer, rafId;
  function observe(){
    rafId = window.requestAnimationFrame(observe);
    if (rafTimer !== false) {
      clearTimeout(rafTimer);
    }
    rafTimer = setTimeout(function() {
      //console.log('on return browser');
      window.cancelAnimationFrame(rafId);
    }, 1000);
  };
  observe();
}
