import {likeTop} from "../likeTop";

export {createLikeNewsTopBlock};

function createLikeNewsTopBlock(liked, options) {
  const $likeBlock = document.createElement('span');
  $likeBlock.classList.add('label-round', 'is-palepink','is-item','btn-iine-top');
  $likeBlock.dataset.likeUrl = options.like_url;
  $likeBlock.dataset.likeAuth = options.like_auth;
  $likeBlock.dataset.likeBody = options.like_body;
  {
    const $icon = document.createElement('i');
    $icon.classList.add('babysymbol');
    if (liked) {
      $likeBlock.style.cursor = 'default';
      $icon.classList.add('babysymbol-heart');
      $icon.style.transform = 'scale(1.3, 1.3)';
    } else {
      $likeBlock.classList.add('gtm-nice-1');
      $icon.classList.add('babysymbol-heartblank');
    }
    $likeBlock.appendChild($icon);

    const $span = document.createElement('span');
    $span.classList.add('ml5', 'is-num');
    $span.textContent = options.likes_count;
    $likeBlock.appendChild($span);
  }
  likeTop([$likeBlock]);
  return $likeBlock;
}
