import {createPaginatorBlock} from "./elementCreators/createPaginatorBlock";
import {createPaginatorIndexBlock} from "./elementCreators/createPaginatorIndexBlock";

export {setPagination};

async function setPagination(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.appendChild(createPaginatorBlock(el,el.dataset.url,parseInt(el.dataset.total),parseInt(el.dataset.current)));
      if(parseInt(el.dataset.showIndex) === 1){
        el.appendChild(createPaginatorIndexBlock(el,parseInt(el.dataset.total),parseInt(el.dataset.current)));
      }
    });
  }
}
