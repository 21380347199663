import createAPI from './APIRequest';

export {clipNewsBottom};

const API = createAPI();
function _clipNews(el) {
  const $babysymbol = el.querySelector('.babysymbol');
  $babysymbol.addEventListener('animationend', (event) => {
    // CSSアニメーションが終了したら必要ないのでクラスを削除する
    el.classList.remove('is-on');
    $babysymbol.classList.add('c-pink');
    $babysymbol.classList.remove('c-gray');
    $babysymbol.style.color = null;
  });
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    const $top = document.querySelector('.js-clip-news-top');
    const $side = document.querySelector('.js-clip-news-side');
    const $clipimg = document.getElementById('clip_notification');
    $current.classList.toggle('is-on');
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        $clipimg.classList.remove('is-on');
        $clipimg.classList.remove('is-off');
        $current.classList.add('is-loading');
        $babysymbol.classList.remove('babysymbol-clip');
      },
      (response) => {
        $current.classList.remove('is-loading');
        $babysymbol.classList.add('babysymbol-clip');

        if ($current.dataset.clipMethod === 'POST') {
          updatePost($current,$clipimg,$top,$side,response.total_count);
        } else {
          updateDelete($current,$clipimg,$babysymbol,$top,$side,response.total_count);
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        }else if(status === 422) {
          $current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
          //既にクリップしていたら
          if(response.data['news_id'] && response.data['news_id']['duplicateError']){
            updatePost($current,$clipimg,$top,$side,null);
          }
        }else if(status === 404 && $current.dataset.clipMethod === 'DELETE') {
          $current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
          updateDelete($current,$clipimg,$babysymbol,$top,$side,null);
        } else {
          $current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
        }
      }
    );
  });
}

function updatePost($current,$clipimg,$top,$side,total_count){
  // ポップアップCSSアニメーション開始
  $clipimg.classList.remove('is-off');
  $clipimg.classList.add('is-on');
  // dataset更新
  $current.dataset.clipMethod = 'DELETE';
  // カウンター更新
  if($current.querySelector('.clips-counter') && total_count !== null) {
    $current.querySelector('.clips-counter').textContent = total_count;
  }
  // アイコンCSSアニメーション開始
  $current.classList.add('is-on');

  if($top) {
    $top.dataset.clipMethod = 'DELETE';
    if($top.querySelector('.clips-counter') && total_count !== null) {
      $top.querySelector('.clips-counter').textContent = total_count;
    }
    $top.querySelector('i.babysymbol-clip').classList.remove('c-gray');
    $top.querySelector('i.babysymbol-clip').classList.add('c-pink');
  }

  if($side) {
    $side.dataset.clipMethod = 'DELETE';
    {
      const $sideOn = document.getElementById('clipDetail');
      $sideOn && $sideOn.classList.add('is-on');
    }
  }
}

function updateDelete($current,$clipimg,$babysymbol,$top,$side,total_count){
  // ポップアップCSSアニメーション開始
  $clipimg.classList.remove('is-on');
  $clipimg.classList.add('is-off');
  // dataset更新
  $current.dataset.clipMethod = 'POST';
  // html 更新
  $current.classList.remove('is-on');

  // カウンター更新
  if($current.querySelector('.clips-counter') && total_count !== null) {
    $current.querySelector('.clips-counter').textContent = total_count;
  }

  $babysymbol.classList.add('c-gray');
  $babysymbol.classList.remove('c-pink');
  $babysymbol.style.color = null;

  if($top) {
    $top.dataset.clipMethod = 'POST';
    if($top.querySelector('.clips-counter') && total_count !== null) {
      $top.querySelector('.clips-counter').textContent = total_count;
    }
    $top.querySelector('i.babysymbol-clip').classList.remove('c-pink');
    $top.querySelector('i.babysymbol-clip').classList.add('c-gray');
  }

  if($side) {
    $side.dataset.clipMethod = 'POST';
    {
      const $sideOn = document.getElementById('clipDetail');
      $sideOn && $sideOn.classList.remove('is-on');
    }
  }

}

async function clipNewsBottom(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipNews(el));
  }
}
