export {setDeleteTarget};

async function setDeleteTarget(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      el.addEventListener('click',event => {

        Array.prototype.slice.call(document.querySelectorAll('.js-modal-comment-delete'), 0).forEach(modal => {
          Array.prototype.slice.call(modal.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
          Array.prototype.slice.call(modal.querySelectorAll('.errors'), 0).forEach($error => $error.remove());
        });

        let target_id = el.dataset.targetId;
        let target_Comment = el.dataset.targetComment;
        let target_page = el.dataset.targetPage;
        document.querySelector('.js-modal-comment').textContent = target_Comment;
        let submit = document.querySelector('.is-submit');
        submit.dataset.commentId = target_id;
        submit.dataset.comment = target_Comment;
        submit.dataset.page = target_page;
      });
    });

  }
}
