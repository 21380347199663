import {clipNewsBottom} from "../clipNewsBottom";

export {createClipNewsBottomBlock};

function createClipNewsBottomBlock(clipped, options) {
  const $clipBlock = document.createElement('button');
  $clipBlock.classList.add('button', 'is-rounded', 'is-small', 'is-clip', 'js-clip-news-bottom', 'gtm-clip-n2');
  $clipBlock.dataset.clipUrl = options.url;
  $clipBlock.dataset.clipMethod = clipped ? 'DELETE' : 'POST';
  $clipBlock.dataset.clipAuth = options.auth;
  $clipBlock.dataset.clipBody = options.body;
  $clipBlock.dataset.clipLogin = options.login;
  {
    const $icon = document.createElement('span');
    $icon.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5', (clipped ? 'c-pink' : 'c-gray'));
    $clipBlock.appendChild($icon);
  }
  {
    const $span = document.createElement('span');
    $span.classList.add('clips-counter');
    $span.textContent = options.clips_count;
    $clipBlock.appendChild($span);
  }
  {
    const $span = document.createElement('span');
    $span.textContent = 'クリップ';
    $clipBlock.appendChild($span);
  }
  clipNewsBottom([$clipBlock]);
  return $clipBlock;
}
