import createAPI from './APIRequest';

export {clipKnowledge};

const API = createAPI();
function _clipKnowledge(el) {
  const $babysymbol = el.querySelector('.babysymbol');
  $babysymbol.addEventListener('animationend', (event) => {
    // CSSアニメーションが終了したら必要ないのでクラスを削除する
    el.classList.remove('is-on');
    $babysymbol.classList.add('c-pink');
    $babysymbol.classList.remove('c-gray');
    $babysymbol.style.color = null;
  });
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    $current.classList.toggle('is-on');
    let clipimg = document.getElementById('clip_notification');
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        $current.classList.add('is-loading');
        $babysymbol.classList.remove('babysymbol-clip');
      },
      (response) => {
        $current.classList.remove('is-loading');
        $babysymbol.classList.add('babysymbol-clip');
        if ($current.dataset.clipMethod === 'POST') {
          // ポップアップCSSアニメーション開始
          clipimg.classList.add('is-on');
          clipimg.classList.remove('is-off');
          // dataset更新
          $current.dataset.clipMethod = 'DELETE';
          // カウンター更新
          $current.querySelector('.clips-counter').textContent = response.total_count;
          // アイコンCSSアニメーション開始
          $current.classList.add('is-on');
        } else {
          // ポップアップCSSアニメーション開始
          clipimg.classList.remove('is-on');
          clipimg.classList.add('is-off');
          // dataset更新
          $current.dataset.clipMethod = 'POST';
          // カウンター更新
          $current.querySelector('.clips-counter').textContent = response.total_count;
          // html 更新
          $current.classList.remove('is-on');

          $babysymbol.classList.add('c-gray');
          $babysymbol.classList.remove('c-pink');
          $babysymbol.style.color = null;
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        } else {
          $current.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
        }
      }
    );
  });
}

async function clipKnowledge(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipKnowledge(el));
  }
}
