
export {createBabyfoodRecipesBlock};

function createBabyfoodRecipesBlock(data, options) {
  if (data.recipes.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('swiper-wrapper','recipe-list');
    {
      data.recipes.forEach(($row, index) => {
        const $slide = document.createElement('div');
        $slide.classList.add('swiper-slide','recipe-wrap');
        {
          const $a = document.createElement('a');
          $a.href = document.location.origin + '/baby-food-recipe/recipe/' + $row.id;
          {
            const $figure = document.createElement('figure');
            $figure.classList.add('is-recipe-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img');
              $img.src = $row.photo;
              $img.alt = $row.title;
              $img.loading = 'lazy';
              $img.style.width = '100%';
              $img.style.height = 'auto';
              $figure.appendChild($img);
            }
            $a.appendChild($figure);

            const $title_div = document.createElement('div');
            $title_div.classList.add('is-recipe-info');
            {
              const $title = document.createElement('div');
              $title.classList.add('is-recipe-title','line-clamp','is-2');
              $title.textContent = $row.title;
              $title_div.appendChild($title);
            }
            $a.appendChild($title_div);
          }
          $slide.appendChild($a);

          if(options.isLogin){
            const $button = document.createElement('button');
            $button.classList.add('recipe-clip', 'is-clip', 'js-clip-recipe', 'gtm-clip-recipe');
            $button.dataset.clipUrl = options.clipUrl;
            if ($row.is_clipped) {
              $button.dataset.clipMethod = 'DELETE';
              $button.classList.add('is-on');
            } else {
              $button.dataset.clipMethod = 'POST';
            }
            $button.dataset.clipBody = 'recipe_id=' + $row.id;
            $button.dataset.clipLogin = options.urlLogin;
            $button.dataset.clipNotification = '1';
            {
              const $span1 = document.createElement('span');
              $span1.classList.add('babysymbol', 'babysymbol-clip');
              $button.appendChild($span1);
            }
            $slide.appendChild($button);
          }else{
            const $button = document.createElement('button');
            $button.classList.add('recipe-clip', 'is-clip');
            {
              const $a = document.createElement('a');
              $a.href = options.urlLogin;
              {
                const $span2 = document.createElement('span');
                $span2.classList.add('babysymbol', 'babysymbol-clip');
                if($row.is_clipped){
                  $button.classList.add('is-on');
                }
                $a.appendChild($span2);
              }
              $button.appendChild($a);
            }
            $slide.appendChild($button);
          }
        }
        $div.appendChild($slide);
      });
    }
    return $div;
  }
}
