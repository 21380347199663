import createAPI from './APIRequest';

export {modalCommentReport};

const API = createAPI();
async function modalCommentReport(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {

      let kind = document.getElementById('report_kind');
      let comment = document.getElementById('report_comment');
      let submit = document.querySelector('.is-submit');
      let cancel = document.querySelector('.is-cancel');

      //コメント投稿
      onSubmit(el,kind,comment,submit,cancel);

      //コメント投稿
      onCancel(el,kind,comment,cancel);

    });
  }
}

function onSubmit(el,kind,comment,submit,cancel) {
  submit.addEventListener('click',event => {
    let recaptcha = document.getElementById('g-recaptcha-response').value;
    if(recaptcha.length > 0){
      let comment_id = submit.dataset.commentId
      let body = submit.dataset.postBody + '&comment_id=' + comment_id + '&comment=' + comment.value + '&kind=' + kind.value + '&g-recaptcha-response=' + recaptcha;

      if(parseInt(comment_id) === -1) return;

      API.send(
        submit.dataset.postUrl,
        'POST',
        submit.dataset.postAuth,
        body,
        () => {
        },
        (response) => {
          cancel.click();
        },
        (status, response) => {
          if(status === 422){
            renderError(el,response.data);
          }else{
            cancel.click();
          }
        }
      );
    }
  });
}

function renderError($form,allErrors) {

  Array.prototype.slice.call($form.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
  Array.prototype.slice.call($form.querySelectorAll('.errors'), 0).forEach($error => $error.remove());

  for (let model in allErrors) {
    let errors = allErrors[model];
    for (let field in errors) {
      let $target = document.getElementById(field);
      if (!$target) {
        continue;
      }
      $target.classList.add('is-danger');
      let fieldErrors = errors[field];
      let $fieldErrors = document.createElement('div');
      $fieldErrors.classList.add('errors', 'help', 'is-danger');
      if (Array.isArray(fieldErrors)) {
        for (let i = 0; i < fieldErrors.length; i++) {
          for (let subField in fieldErrors[i]) {
            let subFieldErrors = fieldErrors[i][subField];
            for (let key in subFieldErrors) {
              let $fieldError = document.createElement('p');
              $fieldError.textContent = subFieldErrors[key];
              $fieldErrors.appendChild($fieldError);
            }
          }
        }
      } else {
        for (let key in fieldErrors) {
          let $fieldError = document.createElement('p');
          $fieldError.textContent = fieldErrors[key];
          $fieldErrors.appendChild($fieldError);
        }
      }
      $target.parentNode.insertBefore($fieldErrors, $target.nextSibling);
    }
  }


}

function onCancel($form,kind,comment,cancel) {
  cancel.addEventListener('click',event => {
    kind.value = '';
    comment.value = '';

    Array.prototype.slice.call($form.querySelectorAll('.is-danger'), 0).forEach($element => $element.classList.remove('is-danger'));
    Array.prototype.slice.call($form.querySelectorAll('.errors'), 0).forEach($error => $error.remove());
  });
}
