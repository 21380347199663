import {calculateBirth} from "./calculateBirth";

export {setActiveMonth}

function _setActiveMonth(elements,options){
  elements.forEach( el => {
    let childAge1 = 0;
    let childAge2 = 0;
    let childAge3 = 0;
    let childAge4 = 0;
    if (options) {
      if (options.child1 != '') {
        childAge1 = calculateBirth(options.child1);
      }
      if (options.child2 != '') {
        childAge2 = calculateBirth(options.child2);
      }
      if (options.child3 != '') {
        childAge3 = calculateBirth(options.child3);
      }
      if (options.child4 != '') {
        childAge4 = calculateBirth(options.child4);
      }
    }else {
      const childBirth = JSON.parse(el.dataset.childBirth)
      if (childBirth.child1 != '') {
        childAge1 = calculateBirth(childBirth.child1);
      }
      if (childBirth.child2 != '') {
        childAge2 = calculateBirth(childBirth.child2);
      }
      if (childBirth.child3 != '') {
        childAge3 = calculateBirth(childBirth.child3);
      }
      if (childBirth.child4 != '') {
        childAge4 = calculateBirth(childBirth.child4);
      }
    }
    const links = el.getElementsByClassName('is-link');
    const arrayLinks = Array.prototype.slice.call(links);
    let start = 0;
    arrayLinks.forEach($link => {
      if (childAge1 != 0)
      {
        checkMonth($link, start, childAge1.months);
      }
      if (childAge2 != 0)
      {
        checkMonth($link, start, childAge2.months);
      }
      if (childAge3 != 0)
      {
        checkMonth($link, start, childAge3.months);
      }
      if (childAge4 != 0)
      {
        checkMonth($link, start, childAge4.months);
      }
      start++;
    })
  });
}

function checkMonth($link, start, months) {
  if (start <= 11 && (start == months)) {
    $link.classList.add('is-now');
  }
  if (start == 12 && (months == 12 || months == 13)) {
    $link.classList.add('is-now');
  }
  if (start == 13 && (months == 14 || months == 15)) {
    $link.classList.add('is-now');
  }
  if (start == 14 && (months == 16 || months == 17)) {
    $link.classList.add('is-now');
  }
  if (start == 15 && (months >= 18 && months <= 23)) {
    $link.classList.add('is-now');
  }
}

async function setActiveMonth(elements,options) {
  if (elements.length > 0) {
    if (!options) {
      options = '';
    }
    _setActiveMonth(elements,options);
  }
}
